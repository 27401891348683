@import "../../public/style.scss";

.User{
  height: 100vh;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  //align-items: center;
  background-color: rgba( $l7-frame-color, 0.25 );
  //overflow: auto;
  overflow: hidden;
  width: 100vw;
  .Control {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    height: 35px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: sticky;
    top:0px;
    background-color: rgba( $l7-white-color, 0.9 );
    backdrop-filter: blur(4px);
    z-index : 5;
    .Back {
      padding : 0rem 1rem;
      height: 100%;
      color : rgba( $l7-dark-color, 0.25 );
      text-decoration: none;
      transition: all 0.25s ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      cursor: pointer;
      &:hover {
        color : rgba( $l7-frame-color, 1 );
      }
      &.Selected {
        color : rgba( $l7-frame-color-sub, 1 );
      }
    }
  }
  .Content {
    display: flex;
    flex-direction: column;
    //flex-grow: 1;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    margin: auto;
    //height: 100%;
    //overflow: hidden;
    .Holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: default;
      .Title {
        color : rgba( $l7-dark-color, 0.5 );
        font-size: 80%;
      }
      .Token {
        color : rgba( $l7-dark-color, 1 );
        font-weight: lighter;
        font-size: 400%;
      }
    }
    &.Top {
      justify-content: flex-start;
      margin: 0px;
      flex-grow: 1;
      width: fit-content;
    }
  }
  .Topup-Btn {
    border : 2px solid rgba( $l7-frame-color-sub, 1 );
    color : rgba( $l7-frame-color-sub, 1 );
    text-decoration: none;
    height: 40px;
    width : 300px;
    transition: all 0.25s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    &:hover {
      background-color: rgba( $l7-frame-color-sub, 1 );
      color : rgba( $l7-white-color, 1 );
    }
  }
}

@media screen and (max-width: 951px)  {
  .User {

  }
}

@media screen and (max-width: 751px)  {
  .User{

  }
}