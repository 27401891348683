@import "../../../public/style.scss";

.TogoHistory {
    height: calc(100vh - 35px);
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100vw;
    //justify-content: center;
    //align-items: center;
    //flex-grow: 1;
    .P-Title {
      width: 100vw;
      font-size: 150%;
      font-weight: bolder;
      color : rgba($l7-frame-color-sub, 1);
      text-align: center;
      //margin: 1rem auto;
      padding : 0.5rem 0rem;
      flex-shrink: 0;
      position: sticky;
      top: 0px;
      left:0px;
      background-color: rgba($l7-white-color, 1);
      z-index : 6;
    }
    .Row {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      //max-width: 751px;
      cursor: default;
      margin: 0rem auto;
      &.Title {
        font-weight: bold;
        background-color: rgba($l7-frame-color-2-sub, 1);
        border-bottom: 1px solid rgba($l7-dark-color, 0.05);
        position: sticky;
        top:47.5px;
        z-index: 5;
        //padding: 0.5rem 0rem;
        position: sticky;
        //top: calc( 70px +  1rem) ;
        .Td {
          font-size: 100%;
          //padding : 0rem 0rem;
          
        }
      }
      .Td {
        width: 100px;
        text-align: center;
        font-size: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding : 0.5rem 0.5rem;
        border-left: 1px solid rgba($l7-dark-color, 0.05);
        word-break: break-word;
        &:first-child {
          border-left-width: 0px;
        }
      }
    }
    .P-Holder {
      display: flex;
      flex-direction: column;
      //justify-content: center;
      justify-content: flex-start;
      //flex-grow: 1;
      
      padding-bottom: 4rem;
    
      
    }

    .Th-Popup {
      position: absolute;
      top:0px;
      left:0px;
      right : 0px;
      bottom: 0px;
      transition: all 0.25s ease-in;
      z-index : -1;
      opacity: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &.Show {
        z-index : 10;
        opacity: 1;
      }
      .Th-Frame {
        background: rgba($l7-dark-color, 0.50);
        backdrop-filter: blur(5px);
        z-index : 1;
        position: absolute;
        top:0px;
        left:0px;
        right : 0px;
        bottom: 0px;
      }
      .Th-View {
        margin: 1rem 1rem;
        margin-bottom: 5rem;
        position: relative;
        z-index : 5;
        height : calc( 100vh - 6rem - 2rem);
        width : calc( 100% - 2rem - 2rem);
        background-color: rgba($l7-white-color, 1);
        padding : 1rem 1rem;
        max-width: 500px;
        border-radius: 0.5rem;
        box-shadow: 0px 0px 5px rgba($l7-dark-color, 0.5);
        display: flex;
        flex-direction: column;
        cursor: default;
        .Th-item {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          //overflow: auto;
          align-content: center;
          position: relative;
          h2 {
            text-align: center;
          }
          h4 {
            text-align: center;
          }
          .I-Column{
            display: flex;
            flex-direction: column;
            
          }
          .I-Row{
            display: flex;
            flex-direction: row;
          }
          .I-Line-1 {
            display: flex;
            flex-direction: row;
            .Price {
              margin-left: auto;
            }
          }
          .I-Line-2 {
            display: flex;
            flex-direction: row;
            margin-left: 0.5rem;
            .Price {
              margin-left: auto;
              color : rgba($l7-dark-color, 0.5);
              font-size: 80%;
            }
          }
          .I-Line-3 {
            display: flex;
            flex-direction: row;
            margin-left: 1.0rem;
            .Price {
              margin-left: auto;
              color : rgba($l7-dark-color, 0.5);
              font-size: 80%;
            }
          }
          .I-Line-4 {
            display: flex;
            flex-direction: row;
            margin-left: 1.5rem;
            .Price {
              margin-left: auto;
              color : rgba($l7-dark-color, 0.5);
              font-size: 80%;
            }
          }
          .I-Line-5 {
            display: flex;
            flex-direction: row;
            margin-left: 2.0rem;
            .Price {
              margin-left: auto;
              color : rgba($l7-dark-color, 0.5);
              font-size: 80%;
            }
          }
        }
      }
    }
}

@media screen and (max-width: 951px)  {
  .TogoHistory {

  }
}

@media screen and (max-width: 751px)  {
  .TogoHistory {

  }
}