@import "../../public/style.scss";

.Booking {
  display: flex;
  flex-direction: column;
  background-color: rgba( $l7-white-color, 1 );
  z-index : 1;
  min-height: 100vh;
  position: relative;
  &.Booking-Popup {
    position: fixed;
    right:0px;
    top:0px;
    transition: all 0.25s ease-in;
    width : 100%;
    z-index : -9;
    opacity: 0;
    //transform: translateX(320px);
    background-color: rgba( $l7-dark-color, 0.5 );
    backdrop-filter: blur(4px);
    display: flex;
    flex-direction: row;
    .Container {
      margin: 0px;
      margin-left: auto;
      width : calc( 300px - 2rem - 1px);
      transition: all 0.25s ease-in;
      transform: translateX(300px);
      background-color: rgba( $l7-white-color, 0.95 );
      border-left: 1px solid rgba( $l7-dark-color, 0.05 );
      position: relative;
      z-index: 9;
      overflow: auto;
    }
    &.Show {
      z-index : 99;
      opacity: 1;
      .Container {
        //width : 300px;
        transform: translateX(0px);
      }
    }
    .Frame {
      position: absolute;
      top:0px;
      left:0px;
      right : 0px;
      bottom : 0px;
      z-index: 1;
    }
  }
  .Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    //box-shadow: 0px 0px 5px rgba( $l7-dark-color, 0.05 );
    max-width: 751px;
    margin: auto;
    padding : 0rem 1rem;
    height: 100vh;
    //height: fit-content;
    .Topic {
      color : rgba( $l7-dark-color, 1 );
      font-size: 150%;
      margin: 1rem auto;
      flex-shrink: 0;
    }
    .Form {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .Holder {
        display: flex;
        flex-direction: column;
        padding : 0.25rem 0rem;
        .Title {
          color : rgba( $l7-dark-color, 1 );
          font-size: 90%;
          margin: 0.25rem 0rem;
          &.Error {
            color : rgba( red, 1 );
          }
        }
        input, .Textarea {
          border-width: 0px;
          border-bottom: 1px solid rgba( $l7-dark-color, 0.1 );
          color : rgba( $l7-dark-color, 1 );
          padding : 0.25rem 0.5rem;
          height : 30px;
          min-width : calc( 300px - 4rem); 
        }
        select {
          height : 40px;
          color : rgba( $l7-dark-color, 1 );
          border-radius: 0.5rem;
          border: 1px solid rgba( $l7-dark-color, 0.1 );
          padding : 0.25rem 0rem;
        }
        .Statement {
          
        }
        .Switch {
          cursor: pointer;
          text-decoration: underline;
          text-align: center;
          padding : 0.25rem 1rem;
          margin: 0.25rem 1rem;
          color : rgba( $l7-frame-color, 1 );
          transition: all 0.25s ease-in;
          &:hover {
            color : rgba( $l7-frame-color-sub, 1 );
          }
        }
        .Btn, .B-Login {
          cursor: pointer;
          max-height: 30px;
          height: fit-content;
          padding : 0.25rem 0.5rem;
          border-radius: 0.5rem;
          width : 80px;
          margin: 1rem auto;
          transition: all 0.25s ease-in;
          border: 2px solid rgba( $l7-frame-color, 1 );
          color : rgba( $l7-frame-color, 1 );
          text-align: center;
          &:hover {
            background-color: rgba( $l7-frame-color, 1 );
            color : rgba( $l7-white-color, 1 );
          }
        }
        .Underline {
          border-bottom: 1px dashed rgba( $l7-dark-color, 0.1 );
          height: 1px;
        }
        .Phone {
          select {
            width : 60px;
          }
          input {
            margin-left: 0.25rem;
            min-width : calc( 300px - 4rem - 60px - 0.25rem); 
          }
        }
        .Textarea {

        }

        .Line {
          display: flex;
          flex-direction: row;
          .L-Title {
            
          }
          .L-Item {
            margin-left: auto;
          }
          .L-Statement, .R-Statement {
            margin-left: auto;
            //font-weight: bold;
            color : rgba( $l7-frame-color, 1 );
            font-size: 80%;
            margin-top: 1rem;
            cursor: pointer;
            text-decoration: underline;
          }
          .R-Statement {
            margin: auto;
            margin-top: 3rem;
            color : rgba( $l7-dark-color, 1 );
          }
          .L-Total {
            margin-left: auto;
            font-size: 120%;
            font-weight: bold;
          }
        }

        .Submit {
          width : calc( 300px - 3rem);
          background-color: rgba( $l7-frame-color, 1 );
          color : rgba( $l7-white-color, 1 );
          transition: all 0.25s ease-in-out;
          padding: 0.5rem 0rem;
          text-align: center;
          font-size: 120%;
          border-radius: 0.5rem;
          cursor: pointer;
          //margin-top: 3rem;
          margin-top: 1rem;
          &:hover {
            background-color: rgba( $l7-frame-color-sub, 1 );
          }
          &.Loading {
            opacity: 0.5;
          }
        }

        &.Deposit {
          margin: 0.5rem 0rem;
          padding: 0.5rem 0rem;
          border-bottom: 1px solid rgba( $l7-dark-color, 0.05 );
          border-top: 1px solid rgba( $l7-dark-color, 0.05 );
          .Title {
            justify-content: center;
            text-align: center;
            margin-bottom: 0.25rem;
            font-size: 120%;
            //font-weight: bold;
          }
        }

        &.Sticky-Bottom {
          position: sticky;
          bottom : 0.5rem;
          z-index: 5;
          margin-top: 4rem;
          //background-color: rgba( $l7-white-color, 0.9 );
          backdrop-filter: blur(4px);
        }

      }

    }
  }
  .Test-Btn {
    cursor: pointer;
    background-color: rgba( $l7-dark-color, 1 );
    color : rgba( $l7-white-color, 1 );
    transition: all 0.25s ease-in;
    padding:0.5rem 2rem;
    &:hover {
      background-color : rgba( $l7-frame-color-2, 1 );
    }
  }
}

@media screen and (max-width: 951px)  {
  .Booking {


  }
}

@media screen and (max-width: 751px)  {
  .Booking {
    .Container {
      .Form {
        .Holder {
          &.Sticky-Bottom {
            position: relative;
            padding-bottom: 5rem;
            z-index: 5;
            //background-color: rgba( $l7-white-color, 0.9 );
            backdrop-filter: blur(4px);
          }
        }
      }
    }
  }
}