@import "../../../public/style.scss";

.Gift-Wrapper {
  //min-height: calc(100vh - 60px - 2rem);
  height: calc(100vh - 60px - 2rem - 100px);
  display: flex;
  flex-direction: column;
  //justify-content: center;
  max-width: 951px;
  width: 100%;
  margin: 0rem auto;
  overflow: hidden;
  .Control {
    display: flex;
    flex-direction: row;
    width: 100%;
    //height: 40px;
    flex-shrink: 0;
    //border-bottom: 1px solid rgba($l7-accent-color, 0.25);
    .Option-Wrapper {
      display: flex;
      flex-direction : row;
      justify-content : center;
      align-items : center;
      .Option {
        transition: all 0.25s ease-in;
        display: flex;
        flex-direction: row;
        font-size: 100%;
        font-weight: bold;
        padding : 0.5rem 1rem;
        color : rgba($l7-dark-color, 0.25);
        cursor: pointer;
        border-bottom: 2px solid rgba($l7-accent-color, 0);
        &.Selected {
          color : rgba($l7-accent-color, 1);
          border-bottom: 2px solid rgba($l7-accent-color, 1);
        }
      }
      .Fa {
        font-size: 100%;
        font-weight: bold;
        padding : 0.5rem 0.5rem;
        color : rgba($l7-accent-color, 1);
      }
    }
  }
  .Holder {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    transition: all 0.25s ease-in;
    //overflow-x: hidden;
    //overflow-y: auto;
    .Container {
      width: 100%;
      //height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex-shrink: 0;
      flex-grow: 1;
      transition: all 0.25s ease-in;
      &.Find {
        padding-top: 25vh;
        background-color: rgba($l7-accent-color-2, 1);
        .Find-Container {
          display: flex;
          flex-direction: column;
          width: 300px;
          justify-content: center;
          align-items: center;
          .Find-Friend {
            font-size: 200%;
            color : rgba($l7-accent-color, 1);
            font-weight: bolder;
            
          }
          .Phone-Wrapper {
            display: flex;
            flex-direction: row;
            width : 100%;
            .Phone-Region {
              background: rgba( $l7-frame-color, 0.5 );
              margin: auto 0rem;
              padding : 0.5rem;
              border-radius: 0.25rem;
              border-style: none;
              height : calc(30px + 0.25rem);
            }
            .Phone-Input {
              flex-grow: 1;
              transition: all 0.25s ease-in;
              background-color: transparent;
              border-style: none;
              border-bottom: 2px solid rgba( $l7-frame-color-sub, 1 );
              padding: 0.25rem 1rem;
              color : rgba( $l7-frame-color-sub, 1 );
              height : 30px;
              border-top-left-radius: 0.25rem;
              border-top-right-radius: 0.25rem;
              margin-left: 0.25rem;
            }
            &:hover {
              //background-color: rgba( $l7-dark-color, 0.1 );
              //color : rgba( $l7-dark-color, 1 );
            }
          }
          .Button {
            margin-top: 1rem;
              background-color: rgba($l7-frame-color, 1);
              color : rgba($l7-white-color, 1);
              font-size: 125%;
              padding : 0.5rem 0rem;
              //width: calc( 100% + 2px);
              width: calc(300px + 0px );
              transition: all 0.25s ease-in;
              text-align: center;
              cursor: pointer;
              &:hover {
                background-color: rgba($l7-frame-color-2, 1);
              }
          }
          .Share {
            display: flex;
            flex-direction: column;
            padding: 1rem 0rem;
            align-self: flex-start;
            justify-self: flex-start;
            width : 100%;
            .Share-Message {
              font-size: 90%;
              color : rgba($l7-accent-color, 1);
              &.Red {
                color : red;
              }
            }
            .Share-Invite {
              display: flex;
              flex-direction: row;
              justify-content: center;
              .Fa {
                margin : 1rem 0.25rem;
                padding: 0.25rem;
                border: 2px solid rgba($l7-accent-color, 1);
                color : rgba($l7-accent-color, 1);
                font-size: 150%;
                border-radius: 50%;
                transition: all 0.25s ease-in;
                &:hover {
                  background-color: rgba($l7-accent-color, 1);
                  color : rgba( $l7-white-color, 1 );
                }
              }
            }
          }
        }
      }
      &.Gift{  
        padding-bottom: 50vh;
        overflow: auto;
        .G-Wrapper {
          display: flex;
          flex-direction: column;
          padding : 0.5rem 1rem;
          min-width : calc(200px - 2rem);
          max-width: calc(400px - 2rem);
          width: calc(100% - 2rem);
          &.Row {
            flex-direction: row;
            .G-Button {
              min-width: initial;
              &:first-child {
                margin-right: 0.25rem;
              }
            }
          }
          .G-Title {
            color : rgba($l7-frame-color, 1);
            font-size: 0.75;
            padding: 0.5rem 0rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            &.Error {
              color : red;
              &::after {
                content: "*";
              }
            }
          }
          .G-Theme {
            width : calc(100% - 0rem);
            display: flex;
            flex-direction: row;
            position: relative;
            height: fit-content;
            .G-Image-Holder {
              width : 80%;
              position: relative;
              height: fit-content;
              height: 80%;
              //min-height: calc( 200px * 0.8 * 5 / 9 );
              border-radius: 0.5rem;
              //overflow: hidden;
              .G-Image {
                border-radius: 0.5rem;
                border : 1px solid rgba($l7-frame-color, 1);
                position: absolute;
                top:0px;
                left:0px;
                width : 100%;
                //height: calc( 200px * 0.8 * 5 / 9 );
                transition: all 0.25s ease-in;
                z-index : -5;
                opacity: 0;
                &.Selected {
                  position: relative;
                  z-index : 1;
                  opacity: 1;
                  height: auto;
                }
              }
            }
            .G-Fa-Holder {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              //height: 100%;
              width : 10%;
              .G-Fa {
                margin: auto;
                color : rgba($l7-frame-color, 1);
                transition: all 0.25s ease-in;
                font-size: 125%;
                
              }
              &:hover {
                .G-Fa {
                  color : rgba($l7-frame-color-2, 1);
                }
              }
            }
          }
          .G-Input {
            color : rgba($l7-frame-color, 1);
            border: 0px solid rgba($l7-dark-color, 0.75);
            border-bottom: 2px solid rgba($l7-frame-color, 0.75);
            padding : 0.5rem 1rem;

            width : calc( 100% - 2rem - 4px) ;
            min-width: calc( 200px - 2rem - 4px);
            transition: all 0.25s ease-in;
            &:hover {
              background-color: rgba($l7-dark-color, 0.05);
            }
          }
          .G-Select{
            color : rgba($l7-frame-color, 1);
            border: 2px solid rgba($l7-frame-color, 0.75);
            padding : 0.5rem 1rem;
            width : calc(100% - 0rem - 4px);
            //max-width: 200px;
            min-width : calc(200px - 0rem - 4px);
            transition: all 0.25s ease-in;
            height: 50px;
            &:hover {
              background-color: rgba($l7-dark-color, 0.05);
            }
          }
          .G-TextArea {
            color : rgba($l7-frame-color, 1);
            border: 2px solid rgba($l7-frame-color, 0.75);
            padding : 0.5rem 1rem;
            width : calc( 100% - 2rem - 4px);
            min-width: calc( 200px - 2rem - 4px);
            transition: all 0.25s ease-in;
            &:hover {
              background-color: rgba($l7-dark-color, 0.05);
            }
          }
          .G-Button {
            margin-top: 1rem;
            background-color: rgba($l7-frame-color, 1);
            color : rgba($l7-white-color, 1);
            font-size: 125%;
            padding : 0.5rem 0rem;
            width: calc( 100% + 2px);
            min-width: calc(200px + 2px );
            transition: all 0.25s ease-in;
            text-align: center;
            cursor: pointer;
            &:hover {
              background-color: rgba($l7-frame-color-2, 1);
            }
          }
          .G-Badges {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            .G-Badge {
              border: 2px solid rgba( $l7-frame-color, 1 );
              color : rgba( $l7-frame-color, 1 );
              font-size: 85%;
              font-weight: bold;
              border-radius: 2rem;
              padding : 0.25rem 0.5rem;
              margin : 0.25rem;
              cursor: pointer;
              transition: all 0.25s ease-in;
              &:hover {
                background-color : rgba( $l7-frame-color, 1 );
                color : rgba( $l7-white-color, 1 );
              }
            }
          }
        }
      }
      &.Share {
        padding-top: 25vh;
        background-color: rgba($l7-accent-color-2, 1);
        .Share-Wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .Share-Title {
            flex-direction: column;
            justify-content: center;
            color : rgba($l7-dark-color, 1);
            font-weight: bold;
            font-size: 125%;
          }
          .Share-Messages {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .Share-Message {
              font-size: 90%;
              color : rgba($l7-accent-color, 1);
            }
          }
          .Link-Holder {
            display: flex;
            flex-direction: row;
            justify-content: center;
            .Link {
              margin: 0.5rem 0.25rem;
              .Fa {
                margin : 1rem 0rem;
                padding: 0.25rem;
                border: 2px solid rgba($l7-accent-color, 1);
                color : rgba($l7-accent-color, 1);
                font-size: 150%;
                border-radius: 50%;
                transition: all 0.25s ease-in;
                &:hover {
                  background-color: rgba($l7-accent-color, 1);
                  color : rgba( $l7-white-color, 1 );
                }
              }
            }
          }
          .Button {
            margin-top: 1rem;
              background-color: rgba($l7-frame-color, 1);
              color : rgba($l7-white-color, 1);
              font-size: 125%;
              padding : 0.5rem 0rem;
              //width: calc( 100% + 2px);
              width: calc(300px + 0px );
              transition: all 0.25s ease-in;
              text-align: center;
              cursor: pointer;
              &:hover {
                background-color: rgba($l7-frame-color-2, 1);
              }
          }
        }
      }
    }
  }
  &.Top {
    height: calc(100vh - 60px - 2rem - 0px);
  }
}

@media screen and (max-width: 951px)  {
  .Gift-Wrapper {
    .Holder {
      .Gift {

      }
    }
  }
}

@media screen and (max-width: 751px)  {
  .Gift-Wrapper {
    .Holder {
      .Gift {
        .Container {
          display: flex;
          //flex-direction: column;
          flex-grow: 1;
          flex-direction: column;
          flex-wrap: wrap;
          //margin: auto;
          margin : 0.5rem 0rem;
          padding: 1rem 0rem;
          .Card {
            margin: 0.5rem auto;
            //width : 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px)  {
  .Gift-Wrapper {
    .Holder {
      .Gift {
        .Container {
          display: flex;
          //flex-direction: column;
          flex-grow: 1;
          flex-direction: column;
          flex-wrap: wrap;
          margin : 0.5rem 0rem;
          padding: 1rem 0rem;
          .Card {
            margin: 0.5rem auto;
            //width : 100%;
          }
        }
      }
    }
  }
}