
$l7-font : 14px/1.5 'Noto Sans TC', sans-serif;

$l7-bgcolor: var( --l7-bgcolor );
$l7-white-color: var( --l7-white-color );
$l7-dark-color: var( --l7-dark-color  );
$l7-grey-color:var( --l7-grey-color  );
$l7-frame-color:var( --l7-frame-color  );
$l7-frame-color-sub:var( --l7-frame-color-sub  );
$l7-frame-color-2:var( --l7-frame-color-2  );
$l7-frame-color-2-sub:var( --l7-frame-color-2-sub  );

$l7-accent-color:var( --l7-accent-color  );
$l7-accent-color-2:var( --l7-accent-color-2  );
$l7-accent-color-3:var( --l7-accent-color-3  );

$l7-text-color:var( --l7-text-color  );
$l7-text-color-white:var( --l7-text-color-white  );
$l7-text-color-light:var( --l7-text-color-light  );



