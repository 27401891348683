@import "../../public/style.scss";

.Wallet{
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  //justify-content: center;
  .Content {
    display: flex;
    flex-direction: row;
    //flex-grow: 1;
    //justify-self: flex-start;
    background-color: rgba($l7-white-color, 0.9);
    backdrop-filter: blur(4px);
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    margin: 0rem 0rem;
    border-bottom: 1px solid rgba( $l7-dark-color, 0.05 );
    position: sticky;
    top: calc(60px - 2rem);
    z-index: 9;
    &.Top {
      top : 0px;
    }
    .Holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: default;
      .Title {
        color : rgba( $l7-dark-color, 0.5 );
        font-size: 80%;
      }
      .Token {
        color : rgba( $l7-dark-color, 1 );
        font-weight: lighter;
        font-size: 200%;
      }
    }
    a {
      margin-top: auto;
      margin-bottom: 0.5rem;
      .Fa {
        margin: auto 0.5rem;
        margin-bottom: 0rem;
        color :rgba($l7-frame-color, 1);
        font-size: 150%;
        transition: all 0.25s ease-in;
        &:hover {
          color :rgba($l7-accent-color-3, 1);
        }
      }
    }
  }
  .Boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    padding: 2rem 0rem;
    max-width: 1024px;
    margin: 1rem auto;

    .Box {
      display: flex;
      width : 150px;
      height: 150px;
      margin: 0rem 0.25rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba($l7-frame-color, 1);
      border-radius: 0.75rem;
      border: 2px solid rgba($l7-accent-color, 1);
      transition: all 0.25s ease-in;
      text-decoration: none;
      position: relative;
      .Fa {
        color : rgba($l7-white-color, 1);
        font-size: 400%;
      }
      .Text {
        color : rgba($l7-white-color, 1);
        text-decoration: none;
        font-size: 150%;
        font-weight: bolder;
        margin-top: 0.25rem;
      }
      &:first-child {
        border-top-right-radius: 0rem;
        border-bottom-right-radius: 0rem;
      }
      &:last-child {
        border-top-left-radius: 0rem;
        border-bottom-left-radius: 0rem;
      }
      &:hover {
        transform: scale(1.05);
        background-color: rgba($l7-frame-color-2, 1);
      }
    }
  }
}

@media screen and (max-width: 951px)  {
  .Wallet {

  }
}

@media screen and (max-width: 751px)  {
  .Wallet {
    height: calc(100vh - 60px);
  }
}

@media screen and (max-width: 500px)  {
  .Wallet {
  }
}