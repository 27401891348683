@import "../../public/style.scss";

.Pdf {
  display: flex;
  flex-direction: column;
  background-color: rgba( $l7-white-color, 1 );
  z-index : 1;
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: auto;
  .react-pdf__Document {
    //width: 90%;
    //max-width: 951px;
  }
  .Control {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .icon {
      color : rgba( $l7-dark-color, 1 );
      font-size: 125%;
      padding : 0.25rem 0.5rem;
      transition: all 0.25s ease-in-out;
      margin: auto 0.25rem;
      &:hover {
        color : rgba( $l7-frame-color, 1 );
      }
      &.disabled {
        opacity: 0.5;
      }
    }
  }
 
}

@media screen and (max-width: 951px)  {
  .Terms {
    .Wrapper {
      width : calc( 100% - 2rem);
    }
  }
}