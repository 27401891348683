@import "../../public/style.scss";

.Social {
  display: flex;
  flex-direction:row;
  //flex-wrap: wrap;
  background-color: rgba( $l7-white-color, 1 );
  z-index : 1;
  //height: 100vh;
  width:951px;
  margin: 0rem auto;
  .Left{
    display: flex;
    flex-direction: column;
    //flex-grow: 1;
    min-width: 300px;
    margin: 0rem 1rem;
    margin-left: auto;
    .Social-Panel {
      height: auto;
      display: flex;
      flex-direction: row;
      //height: 60px;
      flex-wrap: wrap;
      margin: 1rem auto;
      justify-content: center;
      .SP-Holder {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border : 3px solid rgba( $l7-dark-color, 0.25 );
        display: flex;
        flex-direction: row;
        cursor: pointer;
        margin: auto 0rem;
        margin-right: 0.5rem;
        transition: all 0.25s ease-in;
        z-index : 1;
        position: relative;
        .Logo {
          max-width: 30px;
          max-height: 30px;
          margin: auto;
        }
        &:hover {
          transform: scale(1.2);
          z-index : 2;
        }
        &.Selected {
          border : 3px solid rgba( $l7-frame-color, 1 );
        }
      }
    }
    .Lower {
      display: flex;
      flex-direction: column;
      //width: 451px;
      margin: 3rem auto;
      //margin-right: 2rem;
      justify-content: center;
      margin-top: 0rem;
      .Upper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .Logo-Frame {
          //width:140px;
          //height: 140px;
          width:auto;
          height: auto;
          margin : 1rem auto;
          margin-bottom: 0rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .Logo {
            height: 100%;
            width: 210px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
      .Badges {
        //flex-grow : 1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 0.5rem;
        .Badge {
          font-size: 100%;
          background-color: rgba( $l7-frame-color, 1 );
          color: rgba( $l7-white-color, 1 );
          margin: auto 0rem;
          font-weight: bold;
          border-radius: 2rem;
          padding : 0.25rem 1rem;
          margin-right: 0.5rem;
          cursor: default;
          height: fit-content;
          margin-bottom: 0.25rem;
          &:last-child {
            margin-right: 0rem;
            //margin-bottom: auto;
          }
        }
      }
      .Goto {
        border-radius: 1rem;
        padding : 0.25rem 1rem;
        margin: 1rem auto;
        color : rgba( $l7-frame-color, 1 );
        text-decoration: none;
        font-size: 125%;
        transition: all 0.25s ease-in-out;
        //border: 3px solid rgba( $l7-frame-color, 1 );
        &:hover {
          background-color: rgba( $l7-frame-color, 1 );
          color : rgba( $l7-white-color, 1 );
        }
      }
    }
  }
  .Social-Wrapper {
    height: fit-content;
    //width: 951px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;

    .Right{
      //width : 951px;
      height : auto;
      display: flex;
      flex-direction: column;
      .Frame {
        height:500px;
        width : 500px;
      }
      iframe {
        border: 0px;
        overflow: hidden;
      }
    }
    
  }
}

@media screen and (max-width: 951px)  {
  .Social {
    width: auto;
    .Left{
      //margin: 1rem auto;
      //min-width: none;
      .Social-Panel {
        //justify-content: center;
      }
    }
    .Social-Wrapper {
      //width: auto;
      //flex-direction: column;
    }
  }
}

@media screen and (max-width: 751px)  {
  .Social {
    width: auto;
    flex-direction:column;
    .Left{
      margin: 1rem auto;
      min-width: none;
      .Social-Panel {
        justify-content: center;
      }
    }
    .Social-Wrapper {
      width: 100%;
      flex-direction: column;
      min-height: 400px;
    }
  }
}