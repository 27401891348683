@import "../../../public/style.scss";

.Topup{
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  .Content {
    display: flex;
    flex-direction: row;
    //flex-grow: 1;
    background-color: rgba($l7-white-color, 0.9);
    backdrop-filter: blur(4px);
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    margin: 0rem 0rem;
    border-bottom: 1px solid rgba( $l7-dark-color, 0.05 );
    position: sticky;
    top:00px;
    z-index: 9;
    .Holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: default;
      .Title {
        color : rgba( $l7-dark-color, 0.5 );
        font-size: 80%;
      }
      .Token {
        color : rgba( $l7-dark-color, 1 );
        font-weight: lighter;
        font-size: 200%;
      }
    }
  }
  .T-Header {
    flex-direction: column;
    display: flex;
    padding: 1rem 0rem;
    text-align: center;
    font-size: 150%;
    font-weight: bolder;
    background-color: rgba( $l7-frame-color, 1 );
    color : rgba($l7-white-color, 1)
  }
  .Container {
    display: flex;
    //flex-direction: column;
    //flex-grow: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin: 1rem auto;
    padding: 1rem 0rem;
    max-width: 951px;
    margin-bottom:  0rem;
    padding-bottom: 0rem;
    .Card {
      margin: 0.5rem 0.25rem;
      border: 2px solid rgba( $l7-frame-color, 0.25 );
      backdrop-filter: blur(4px);
      border-radius: 0.75rem;
      width: 300px;
      padding: 0rem;
      height : calc( 300px * 9 / 16 );
      //background-color: rgba( $l7-frame-color-2, 1 );
      transition: all 0.25s ease-in;
      position: relative;
      z-index : 1;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      &:hover {
        z-index : 5;
        transform: scale(1.1);
        background-color: rgba( $l7-frame-color-2-sub, 0.6 );
        box-shadow: 1px 1px 5px rgba( $l7-dark-color, 0.5 );
      }
      .Top {
        margin-top : 0.5rem;
        margin-left: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: rgba( $l7-frame-color, 1 );
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        height: 75px;
        .Dollar {
          margin-right: 0.25rem;
          font-size: 100%;
        }
        .Price {
          font-size: 250%;
          //line-height: 300%;
        }
      }
      .Spliter {
        height: 1px;
        width : calc( 100% - 2rem);
        margin: 0.5rem 1rem;
        background-color: rgba( $l7-white-color, 0.5 );
      }
      .Bottom {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 0rem 1rem;
        align-items: center;
        flex-grow: 1;
        .Wrapper {
          border: 1px solid rgba( $l7-white-color, 0.75 );
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          //width : 50px;
          width: fit-content;
          padding: 0px 0.5rem;
          height : 50px;
          margin-left: 0.5rem;
          border-radius: 0.25rem;
          position: relative;
          .Title {
            //position: absolute;
            font-size: 70%;
            //line-height: 100%;
            flex-shrink: 1;
            color: rgba( $l7-white-color, 0.75 );
            display: flex;
            word-wrap: break-word;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .Body {
            flex-grow: 1;
            font-size: 150%;
            color: rgba( $l7-white-color, 0.75 );
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-weight: lighter;
            
          }
        }
      }
    }
  }

}

@media screen and (max-width: 951px)  {
  .Topup {

  }
}

@media screen and (max-width: 751px)  {
  .Topup {
    height: calc(100vh - 60px);
    .Container {
      display: flex;
      //flex-direction: column;
      flex-grow: 1;
      flex-direction: column;
      flex-wrap: wrap;
      //margin: auto;
      margin : 0.5rem 0rem;
      padding: 1rem 0rem;
      .Card {
        margin: 0.5rem auto;
        //width : 100%;
      }
    }
  }
}

@media screen and (max-width: 500px)  {
  .Topup {
    .Container {
      display: flex;
      //flex-direction: column;
      flex-grow: 1;
      flex-direction: column;
      flex-wrap: wrap;
      margin : 0.5rem 0rem;
      padding: 1rem 0rem;
      .Card {
        margin: 0.5rem auto;
        //width : 100%;
      }
    }
  }
}