@import "../../public/style.scss";

.Payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba( $l7-frame-color, 0.05 );
  flex-grow: 1;
  align-items: center;
  min-height: 100vh;
  .Wrapper {
    width: 250px;
    //height: 300px;
    height: fit-content;
    margin: auto;
    background-color: rgba( $l7-white-color, 1 );
    //box-shadow: 0px 0px 5px rgba( $l7-dark-color, 0.05 );
    border: 1px solid rgba( $l7-dark-color, 0.05 );
    border-radius: 0.5rem;
    padding : 1rem;
    .Title {
      font-size: 120%;
      text-align: center;
      padding: 1rem 0rem;
    }
    .Statements {
      padding: 0.5rem 0rem;
      display: flex;
      flex-direction: column;
      .Statement {
        font-size: 110%;
        color : rgba( $l7-dark-color, 0.8 );
        text-align: center;
        line-height: 95%;
        margin-top: 0.5rem;
      }
      .Warning {
        font-size: 85%;
        color : rgba( red, 0.5 );
        text-align: left;
        line-height: 95%;
        margin-top: 0.25rem;
      }
    }
    .Holder {
      display: flex;
      flex-direction: column;
      padding: 0.5rem 0rem;
      .Input {
        width : 250px;
        border-width: 0px;
        //border-bottom: 2px solid rgba( $l7-dark-color, 0.25 );
        border-bottom: 1px solid rgba( $l7-dark-color, 0.05 );
        border-top: 1px solid rgba( $l7-dark-color, 0.05 );
        padding: 1rem 0rem;
        font-size: 100%;
        text-align: center;
        margin-bottom: 0.5rem;
        color :  rgba( $l7-frame-color, 1 );
        font-size: 110%;
      }
      .Agree {
        margin-top: 0.5rem;
        background-color: rgba( $l7-frame-color, 1 );
        border-width: 0px;
        border-radius: 0.5rem;
        color : rgba( $l7-white-color, 1 );
        padding : 0.5rem 0rem;
        cursor: pointer;
        transition: all 0.25s ease-in-out;
        &:hover {
          background-color: rgba( $l7-frame-color-2, 1 );
        }
      }
    }
  }
  
}

@media screen and (max-width: 951px)  {
  .Payment {

  }
}

@media screen and (max-width: 751px)  {
  .Payment {
    width: auto;
    flex-direction:column;
  }
}