@import "../../public/style.scss";

.Success, .Fail {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .Logo {
    width : 150px;
    margin-bottom: 2rem;
  }
  .Message {
    color : rgba($l7-frame-color-2, 1.0);
    font-size: 150%;
  }
}

@media screen and (max-width: 951px)  {
  .Social {
    width: auto;
    .Left{
      //margin: 1rem auto;
      //min-width: none;
      .Social-Panel {
        //justify-content: center;
      }
    }
    .Social-Wrapper {
      //width: auto;
      //flex-direction: column;
    }
  }
}

@media screen and (max-width: 751px)  {
  .Social {
    width: auto;
    flex-direction:column;
    .Left{
      margin: 1rem auto;
      min-width: none;
      .Social-Panel {
        justify-content: center;
      }
    }
    .Social-Wrapper {
      width: 100%;
      flex-direction: column;
      min-height: 400px;
    }
  }
}