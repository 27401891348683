@import "../../public/style.scss";

.Header {
  display: flex;
  flex-direction: column;
  background-color: rgba( $l7-frame-color-2, 1 );
  position: sticky;
  top:0px;
  z-index : 15;
  min-height: 45px;
  .Large-Frame {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .Logo-Frame {
      display: flex;
      flex-direction: row;
      padding : 1rem 0rem;
      padding-top: 1.5rem;
      border-bottom: 1px solid rgba( $l7-white-color, 0.25 );
      transition: all 0.25s ease-in-out;
      .Logo {
        height : 104px;
        width: 133px; 
        margin: auto;
      }
      &.Disapper {
        opacity: 0;
        padding : 0px;
        border-bottom-width: 0px;
        .Logo {
          height: 0px;
        }
      }
    }
    .Lower {
      display: flex;
      flex-direction: row;
      padding : 0.25em;
      padding-top : 0rem;
      justify-content: center;
      overflow: hidden;
      .Lower-Frame {
        margin : auto;
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        align-items: center;
        .Item {
          color : rgba( $l7-white-color, 1 );
          padding : 0.5rem 1rem;
          margin : auto 0.5rem;
          cursor: pointer;
          transition: all 0.25s ease-in;
          border-top : 4px solid rgba( $l7-frame-color, 0 );
          padding-top:0.75rem;
          //padding-top:0rem;
          font-weight: bold;
          text-decoration: none;

          &:hover {
            color : rgba( $l7-frame-color, 1 );
            border-top : 4px solid rgba( $l7-frame-color, 1 );
            &.Selected {
              color: rgba( $l7-white-color, 1 );
            }
          }
          &.Selected {
            border-top : 4px solid rgba( $l7-frame-color, 1 );
            //background-color: rgba( $l7-frame-color, 1 );
            color: rgba( $l7-frame-color, 1 );
          }
          &.Booking-Btn {
            //margin-left: auto;
            align-self: baseline;
            padding : 0.25rem 1rem;
            margin-top: calc( 0.65rem );
            border: 1px solid rgba( $l7-frame-color, 0.9 );
            color : rgba( $l7-frame-color, 1 );
            &:hover {
              background-color: rgba( $l7-frame-color, 1 );
              color : rgba( $l7-white-color, 1 );
            }
          }
          &.Shop-Btn {
            //margin-left: auto;
            display: flex;
            flex-direction: row;
            align-self: baseline;
            //padding : 0.5rem 1rem;
            //padding-top: 0.75em;
            //margin-top: calc( 0.65rem );
            //border: 1px solid rgba( $l7-frame-color, 0.9 );
            color : rgba( $l7-frame-color, 1 );

            .Text {
              margin-left: 0.5rem;
            }
            .Centered {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 15px;
              height: 15px;
              margin: auto;
              overflow: hidden;
              .Lottie {
                width : 125px;
                height: 125px;
              }
            }
            &:hover {
              background-color: rgba( $l7-frame-color, 1 );
              color : rgba( $l7-white-color, 1 );
            }
          }
        }
      }
    }
    &.Offset {
      background-color: rgba( $l7-frame-color-2, 0.9 );
      backdrop-filter: filter(5px);
    }  
  }
  .Languages {
    position: absolute;
    top: 0.5rem;
    right : 0.5rem;
    color : rgba( $l7-white-color, 1 );
    //background-color: rgba( $l7-frame-color, 0.75 );
    font-weight: normal;
    font-size: 100%;
    width: auto;
    display: flex;
    flex-direction: row;
    padding:0.25rem 0.25rem;
    .Option {
      padding: 0rem 0.5rem;
      margin: auto;
      cursor: pointer;
      transition: all 0.25s ease-in-out;
      border-right: 1px solid rgba( $l7-white-color, 1 );
      &:first-child {
        //padding-left: 0.5rem;
      }
      &:last-child {
        //padding-right: 0.5rem;
        border-right: 0px solid rgba( $l7-white-color, 1 );
      }
      margin: auto 0rem;
      &.Selected {
        display: none;
      }
      &:hover {
        background-color: rgba( $l7-frame-color, 0.75 );
        &:after {
          background-color: rgba( $l7-frame-color, 0.75 );
        }
      }
    }
    
  }

  .User-Btn {
    position: absolute;
    top: 0.5rem;
    left : 0.5rem;
    color : rgba( $l7-white-color, 1 );
    //background-color: rgba( $l7-frame-color, 0.75 );
    font-weight: normal;
    font-size: 100%;
    width: auto;
    display: flex;
    flex-direction: row;
    padding:0.25rem 0.25rem;
    transition: all 0.25s ease-in;
    cursor: pointer;
    .Text {
      margin: auto 0.25rem;
    }
    .Fa {
      margin: auto 0.25rem;
    }
    &:hover {
      color : rgba( $l7-frame-color, 1 );
    }
  }

  .Small-Frame {
    display: none;
    flex-direction: column;
    .Nav-Bar {
      height:60px;
      display: flex;
      flex-direction: row;
      flex-shrink: 0;
      .Icon {
        margin: auto 0rem;
        margin-left: 0.5rem;
        font-size: larger;
        color : rgba( $l7-white-color, 1 );
        width:40px;
        height:25px;
        transition: all 0.25s ease-in;
        cursor: pointer;
        &:hover {
          color : rgba( $l7-frame-color, 1 );
        }
      }
      .Logo {
        height:40px;
        width: auto;
        margin: auto;
      }
      .Pad {
        width:40px;
        margin-right: 0.5rem;
      }
    }
    .Nav-Menu {
      height : 0px;
      transition: all 0.25s ease-in-out;
      opacity: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      
      &.Show {
        opacity: 1;
        padding: 1rem 1rem;
        height: auto;
        background-color: rgba( $l7-white-color, 1 );
        flex-grow:1;
        height: 100vh;
      }
      .Item {
        cursor: pointer;
        padding : 1rem 1rem;
        text-align: left;
        color : rgba( $l7-dark-color, 1 );
        font-size: 125%;
        text-decoration: none;
        transition: all 0.25s ease-in;
        //border-top: 2px solid rgba( $l7-frame-color, 0 );
        border-bottom: 2px solid rgba( $l7-dark-color, 0 );
        &:hover {
          color : rgba( $l7-frame-color, 1 );
          //background-color: rgba( $l7-frame-color, 0.75 );
          //border-top: 2px solid rgba( $l7-frame-color, 1 );
        }
      }
      .Languages {
        background-color: transparent;
        font-weight: bolder;
        font-size: 125%;
        width: auto;
        display: flex;
        flex-direction: row;
        padding:1.5rem 0.25rem;
        border-top: 1px solid rgba( $l7-frame-color, 0.1 );
        border-bottom: 1px solid rgba( $l7-frame-color, 0.1 );
        .Option {
          padding: 0.5rem 0.5rem;
          margin: auto;
          cursor: pointer;
          margin : auto 0.5rem;
          transition: all 0.25s ease-in-out;
          border-right: 1px solid rgba( $l7-white-color, 1 );
          color : rgba( $l7-dark-color, 0.5 );
          &:first-child {
            //padding-left: 0.5rem;
          }
          &:last-child {
            //padding-right: 0.5rem;
            border-right: 0px solid rgba( $l7-white-color, 1 );
          }
          &.Selected {
            display: block;
            color : rgba( $l7-dark-color, 1 );
            border-bottom: 2px solid rgba( $l7-frame-color, 1 );
          }
          &:hover {
            background-color: rgba( $l7-frame-color, 0.75 );
          }
        }
        
      }
    }
    
  }
}

@media screen and (max-width: 951px)  {
  .Header {
    background-color: rgba( $l7-frame-color-2, 1 );
    backdrop-filter: blur(5px);
    .Large-Frame {
      display: none;
    }
    .Small-Frame {
      display: flex;
      .Languages {
        display: block;
        position: relative;
        //margin :0rem;
        //padding:0.5rem 0rem;
        width:40px;
        display: flex;
      }
    }
    .Languages {
      margin: 0rem;
      display: none;
      //top:10px;
      //left:0.5rem;
      //display: none;
    } 
    .User-Btn {
      left:auto;
      right:0.5rem;
      top:1rem;
    }
  }
}