@import "../../public/style.scss";

.Terms {
  display: flex;
  flex-direction: column;
  background-color: rgba( $l7-white-color, 1 );
  z-index : 1;
  min-height: 100vh;
  .T-Header {
    padding : 1rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: rgba( $l7-white-color, 1 );
    background-color: rgba( $l7-frame-color-2, 1 );
    font-size: 200%;
  }
  .Wrapper {
    width : calc( 951px - 2rem);
    padding : 3rem 1rem;
    margin: 0rem auto;
    padding-top: 0rem;
    .Sentence {
      display: flex;
      flex-direction: row;
      //flex-wrap: wrap;
      justify-content: flex-start;
      .Empty {
        padding: 0.5rem 1rem;
        margin: auto 0rem;
      }
      .Text {
        margin: auto 0rem;
      }
      .Title {
        font-size: 125%;
        font-weight: bold;
        margin: 2rem 0rem;
        margin-bottom: 1rem;
      }
    }
  }
}

@media screen and (max-width: 951px)  {
  .Terms {
    .Wrapper {
      width : calc( 100% - 2rem);
    }
  }
}