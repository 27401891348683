@import "../../public/style.scss";

.Brand {
  display: flex;
  flex-direction: column;
  background-color: rgba( $l7-white-color, 1 );
  z-index : 1;
  min-height: 100vh;
  position: relative;
  .B-Cover {
    overflow: hidden;
    height:400px;
    position: sticky;
    top:0px;
    z-index :-1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    //backdrop-filter: blur(2px);
    .Img {
      //filter: blur(2px);
      position: relative;
      z-index: 1;
      object-fit: cover;
      height: 100%;
      width:100%;
      &.Mock {
        height:300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color : rgba( $l7-white-color, 1 );
        font-size: 300%;
        text-align: center;
        background-color: rgba( $l7-dark-color, 0.5 );
      }
    }
    .Message {
      position: absolute;
      top:0px;
      bottom:0px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      left:0px;
      right:0px;
      z-index: 2;
      background-color: rgba( $l7-dark-color, 0.5 );
      //backdrop-filter: blur(2px);
      margin: auto;
      text-align: center;
      color : rgba( $l7-white-color, 1 );
      font-size: 150%;
      .Char {
        margin: auto 1.5rem;
      }
      .Char-N {
        margin: auto 0.75rem;
      }
    }
  }
  .Body {
    position: sticky;
    top: 40px;
    z-index : 1;
    min-height: 100vh;
    background-color: rgba( $l7-white-color, 1 );
    backdrop-filter: blur(2px);
    //border-top: 2px solid rgba( $l7-frame-color, 1 );
    width:100%;
    padding: 2rem 0rem;
    .Holder {
      display : flex;
      flex-direction: row;
      width: 951px;
      margin: 1rem auto;
      .Left {
        display: flex;
        flex-direction: column;
        min-width: 250px;
        .Logo-Frame {
          width: auto;
          height: auto;
          margin : 1rem auto;
          margin-bottom: 0rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .Logo {
            width:210px;
            height: auto;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
        .Badges {
          //flex-grow : 1;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          margin-top: 0.5rem;
          .Badge {
            font-size: 100%;
            background-color: rgba( $l7-frame-color, 1 );
            color: rgba( $l7-white-color, 1 );
            margin: auto 0rem;
            font-weight: bold;
            border-radius: 2rem;
            padding : 0.25rem 1rem;
            margin-right: 0.5rem;
            cursor: default;
            height: fit-content;
            margin-bottom: 0.25rem;
            &:last-child {
              margin-right: 0rem;
              //margin-bottom: auto;
            }
          }
        }
      }
      .Right {
        margin-left: 3rem;
        display: flex;
        flex-direction: column;
        .Social-W {
          //height:30px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          .Icon {
            height: 30px;
            font-size: 150%;
            margin: auto 0.25rem;
            transition: all 0.25s ease-in;
            color : rgba( $l7-frame-color, 1 );
            cursor: pointer;
            &:hover {
              color : rgba( $l7-frame-color-2, 1 );
              transform: scale(1.1);
            }
          }
        }
        .Content {
          display: flex;
          flex-direction: column;
          p {
            color : rgba( $l7-dark-color, 0.6 );
            font-size: 120%;
          }
        }
      }
    }
    .Shops {
      margin: 3rem auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      width:951px;
      .Shop-Box {
        width:400px;
        //height: 400px;
        height: auto;
        margin:0.5rem ;
        background-color: rgba( $l7-frame-color, 0.5 );
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        transition: all 0.25s ease-in;
        position: relative;
        z-index: 1;
        overflow: hidden;
        cursor: pointer;
        text-decoration: none;
        .Bg-Img {
          //width:100%;
          object-fit: cover;
          //margin: auto;
          flex-grow: 1;
          height:275px;
          width: auto;
          opacity: 0.8;
          transition: all 0.25s ease-in;
          //filter: blur(1px);
          &.Mock {
            background-color: rgba( $l7-dark-color, 0.5 );
            color : rgba( $l7-white-color, 1 );
            font-size: 300%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
          }
        }
        .Bottom {
          height: fit-content;
          padding: 1.5rem 1rem;
          z-index : 5;
          width: auto;
          transition: all 0.25s ease-in;
          background-color: rgba( $l7-frame-color-2-sub, 1 );
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          .Title {
            text-align: center;
            font-size: 150%;
            font-weight: bold;
            color : rgba( $l7-dark-color, 1 );
            //height:50px;
            margin-top: auto;
            margin-bottom: 0rem;
          }
          .Address {
            color : rgba( $l7-dark-color, 0.75 );
            font-size: 110%;
            text-align: center;
            margin : 0rem auto;
          }
          .R-Holder {
            //padding: 0.5rem 1rem;
            margin : 0.5rem auto;
            margin-top: 1rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-self: center;
            .Register {
              color : rgba( $l7-frame-color-sub, 1 );
              background-color: transparent;
              border : 1px solid rgba( $l7-frame-color-sub, 1 );
              padding: 0.5rem 1rem;
              border-radius: 0.75rem;
              width: fit-content; 
              transition: all 0.25s ease-in;
              margin-bottom: auto;
              cursor: pointer;
              &:last-child {
                margin-left: 0.25rem;
              }
              &:hover {
                background-color: rgba( $l7-frame-color-sub, 1 );
                border : 2px solid rgba( $l7-frame-color-sub, 1 );
                color : rgba( $l7-white-color, 1 );
              }
            }
          }
        }
        &:hover {
          z-index: 2;
          transform: scale(1.05);
          box-shadow: 0px 0px 10px rgba( $l7-dark-color, 0.5 );
          .Bg-Img {
            transform: scale(1.2);
            opacity: 1;
            //filter: blur(0px);
          }
          .Bottom {
            background-color: rgba( $l7-frame-color-2-sub, 1 );
          }
        }
      }
    }
  }
}



@media screen and (max-width: 951px)  {
  .Brand {
    .B-Cover {
      height:auto!important;
      width: 100%;;
      max-height: 300px;;
      .Img {
        height: auto;
        width: 100%;
        &.Mock {
          height: 150px;
        }
      }
    }
    .Body {
      width: auto;
      .Holder {
        flex-direction: column;
        width: auto;
        .Left {
          .Logo {
            margin: auto;
          }
        }
        .Right {
          margin: auto 2rem;
          .Social-W {
            margin : 1rem 0rem;
          }
        }
      }
      .Shops {
        width:auto;
        .Shop-Box {
          width:300px;
          //height: 300px;
          .Bg-Img {
            height:175px;
          }
        }
      }
    }
  }
}