@import "./public/style.scss";

.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: rgba( $l7-white-color, 1);
  //min-height:200vh;
  font: $l7-font;
  //font-size: 15px;

  .Shoutcut {
    position: fixed;
    bottom:1rem;
    right: 1rem;
    background-color: rgba( $l7-white-color, 1);
    z-index: 14;
    color : rgba( $l7-frame-color-2, 1);
    //padding:0.5rem;
    height : 30px;
    width :30px;
    border-radius: 50%;
    opacity: 0.4;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    border: 2px solid rgba( $l7-white-color, 1);
    &:hover {
      opacity: 1;
    }
  }

  &.Buffer {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba( $l7-frame-color-2-sub, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .B-Logo {
      width : 50%;
      min-width: 150px;
      max-width: 300px;
    }
    .Loading {
      font-size: 150%;
      margin-top: 2rem;
    }
  }

}


@media screen and (max-width: 992px)  {
}

