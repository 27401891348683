@import "../../public/style.scss";

.Popup {
  position : fixed;
  top:0px;
  left: 0px;
  bottom : 0px;
  right : 0px;
  display: flex;
  flex-direction: column;
  background-color: rgba( $l7-dark-color, 0.1 );
  backdrop-filter : blur(5px);
  z-index : 100;
  //opacity: 0;
  height: 100vh;
  transition: all 0.25s ease-in;
  .Text {
    margin: auto;
    font-size: 200%;
    color : rgba($l7-white-color , 1);
  }
  .Close {
    position: absolute;
    top : 0rem;
    right : 0rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    background-color: rgba($l7-frame-color , 2);
    padding : 0.5rem 1rem;
    opacity: 0.5;
    transition: all 0.25s ease-in;
    z-index: 10;
    cursor: pointer;
    .Icon {
      color: rgba($l7-white-color , 1);
      font-size: 100%;
      margin: auto;
      margin-right: 0rem;
    }
    .Text {
      color: rgba($l7-white-color , 1);
      font-size: 100%;
      margin: auto;
      margin-left: 0rem;
      &::before {
        content: "|";
        margin: 0.25rem;
      }
    }
    &:hover {
      opacity: 1;
    }
  }
  .Upper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding : 0rem 5rem;
    transition: all 0.25s ease-in;
    position: relative;
    z-index: 5;
    .Holder {
      width: fit-content;
      height: fit-content;
      margin: auto;
      //margin-bottom: 1rem;
      box-shadow: 0px 0px 10px rgba( $l7-dark-color, 0.9 );
      position: relative;
      z-index: 10;
      transition: all 0.25s ease-in;
      display: flex;
      overflow: hidden;
      border-radius: 0.25rem;
      .Image {
        object-fit: contain;
        //width : fit-content;
        margin: auto;
        max-height: calc( 100vh - 100px );
        transition: all 0.25s ease-in;
        cursor: pointer;
        transform-style: preserve-3d;
      }
      .Image-Cover {
        width : fit-content;
        margin: auto;
        max-height: calc( 100vh - 100px );
        transition: all 0.25s ease-in;
        cursor: pointer;
        transform-style: preserve-3d;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        color : rgba($l7-white-color , 1);
      }
      .Stores-Frame {
        //transition: all 0.25s ease-in;
        opacity: 0;
        z-index : -99;
        position: absolute;
      }
      &.Flip {
        background-color: rgba( $l7-dark-color, 1 );
        .Image {
          filter: blur(5px) ;
          transform: rotateY(180deg);
          opacity: 0.25;
          cursor: pointer;
        }
        .Stores-Frame {
          //transition: all 0.25s ease-in;
          opacity: 1;
          z-index : 5;
          top: 0px;
          bottom : 0px;
          left: 0px;
          right: 0px;
          display: flex;
          flex-direction: column;
          overflow: auto;
          .Top-Panel {
            position: sticky;
            top: 0px;
            z-index: 9;
            background-color: rgba( $l7-dark-color, 0.25 );
            backdrop-filter: blur(5px);
            .Back {
              display: flex;
              flex-direction: row;
              margin: 0.5rem auto;
              margin-left: 0.5rem;
              cursor: pointer;
              color : rgba($l7-white-color , 1);
              transition: all 0.25s ease-in;
              border-radius: 2.5rem;
              border: 1px solid rgba($l7-white-color , 1);
              width: fit-content;
              padding : 0.25rem 1rem;
              .Icon {
                font-size: 85%;
                margin: auto 0.25rem;
                margin-left: 0rem;
              }
              .Title {
                font-size: 85%;
                margin: auto 0rem;
              }
              &:hover {
                background-color: rgba($l7-white-color , 1);
                color : rgba($l7-dark-color , 1);
              }
            }      
          }
          .Mid-Panel {
            display: flex;
            flex-direction: column;
            padding: 2rem 0rem;
            flex-shrink: 0;
            background-color: rgba( $l7-dark-color, 0.25 );
            .Logo {
              height: 50px;
              width : auto;
              object-fit: contain;
              margin-bottom: 2rem;
            }
            .Messages {
              display: flex;
              flex-direction: column;
              justify-content: center;
              color : rgba($l7-white-color , 1);
              text-align: left;
              padding: 0rem 2rem;
              padding-bottom: 0rem;
              .video {
                padding-bottom: 2rem;
                width: 100%;
              }
              .title, .titleu, .titled, .titlem {
                font-size: 200%;
                line-height: 30px;
                min-height: 30px;
                font-weight: bold;
                text-align: center;
                //margin-top: 1.5rem;
                margin-bottom: 2rem;
                //margin-bottom: 0.25rem;

              }
              .titleu {
                margin-bottom: 0.25rem;
              }
              .titlem {
                margin-top: 0.25rem;
                margin-bottom: 0.25rem;
              }
              .titled {
                margin-top: 0.25rem;
              }
              .message, .message1, .appendix, .link {
                font-size: 110%;
                line-height: 22.5px;
                min-height: 22.5px;
                margin-top: 1.5rem;
                text-decoration: none;
                justify-content: row;
                display: flex;
                flex-direction: column;
                text-align: center;
              }
              .message1 {
                margin-top: 0rem;
              }
              .line, {
                margin-top: 1.5rem;
                border-bottom: 1px solid rgba($l7-frame-color , 0.5);
              }
              .message {
                text-align: center;
              }
              .appendix {
                margin-top: 0rem;
                text-align: center;
              }
              .icon-holder {
                display: flex;
                flex-direction: row;
                text-align: center;
                margin: 1.25rem auto;
                margin-top: 2rem;
                border-radius: 3rem;
                border: 2px solid rgba($l7-frame-color , 1);
                position: relative;
                padding-left: 45px;
                cursor: pointer;
                transition : all 0.25s ease-in;
                text-decoration: none;
                .icon {
                  //background-color: rgba($l7-frame-color , 1);
                  color : rgba($l7-frame-color , 1);
                  font-size: 54px;
                  //margin: auto 0.25rem;
                  //margin-left: 1rem;
                  position: absolute;
                  left: -7px;
                  top:-7px;
                  transition : all 0.25s ease-in;
                }
                .text {
                  line-height: 40px;
                  height : 40px;
                  color : rgba($l7-frame-color , 1);
                  font-size: 150%;
                  margin: auto 0.25rem;
                  margin-right: 1rem;
                  transition : all 0.25s ease-in;
                }
                &:hover {
                  border: 2px solid #6BD249;
                  .icon {
                    color : #6BD249;
                  }
                  .text {
                    color : #6BD249;
                  }
                }
              }

              .gallery {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                //background: rgba($l7-frame-color , 0.05) ;
                margin: 1rem auto;
                align-items: center;
                justify-content: center;
                .img {
                  min-width : 200px;
                  width: calc( 50% - 0.5rem);
                  margin: 0.25rem;
                  transition: all 0.25s ease-in;
                  &.Full {
                    width: 100%;
                  }
                }
              }

              &:first-child {
                margin-top: 0rem;
              }
            }
          }
          .Bottom-Panel {
            display: flex;
            flex-direction: column;
            padding : 0rem 2rem;
            flex-grow: 1;
            //overflow: auto;
            padding-bottom: 4rem;
            flex-shrink: 0;
            background-color: rgba( $l7-dark-color, 0.25 );
            .B-Title {
              color : rgba($l7-white-color , 1);
              font-weight: bold;
              margin: 1rem auto;
            }
            .Store-Group {
              display: flex;
              flex-direction: column;
              border-top: 1px solid rgba($l7-frame-color , 0.5);
              //border-bottom: 2px solid rgba($l7-frame-color , 0.5);
              //margin-bottom: 1rem;
              &:last-child {
                border-bottom: none;
              }
              .Store-Group-Title {
                font-size: 125%;
                color : rgba($l7-white-color , 1);
                font-weight: 300;
                text-align: center;
                //padding: 0.5rem 0rem;
                margin-bottom: 1rem;
                padding-top: 2rem;
                padding-bottom: 1rem;
                line-height: 25px;
                //border-bottom: 1px solid rgba($l7-frame-color , 0.5);
                display: flex;
                flex-direction: column;
                div {

                }
              }
              .Brand-Holder {
                display: flex;
                flex-direction: row;
                padding : 0.5rem 0rem;
                margin-bottom: 0.5rem;
                //border-bottom: 1px dashed rgba($l7-white-color , 0.2);
                &:last-child {
                  border-bottom: none;
                }
                .Logo {
                  width: 50px;
                  height : 50px;
                  margin: auto 1rem;
                  margin-top: 0rem;
                  padding : 0rem;
                  border-radius: 50%;
                  background-color: rgba($l7-white-color , 1);
                }
                .Shop-Holder {
                  display: flex;
                  flex-direction: column;
                  //flex-shrink: 0;
                  .Brand-Name {
                    font-size: 125%;
                    color : rgba($l7-white-color , 1);
                    text-align: left;
                    font-weight: 300;
                    height: 60px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    //flex-shrink: 0;
                    color:  rgba($l7-frame-color-2-sub , 1);
                  }
                  .ShopBox {
                    display: flex;
                    flex-direction: column; 
                    margin-bottom: 0rem;
                    //flex-shrink: 0;
                    .Name {
                      color:  rgba($l7-frame-color , 1);
                      font-size: 125%;
                      margin-bottom: 0.25rem;
                      cursor : default;
                      display: none;
                      //flex-shrink: 0;
                    }
                    .Address, .Tel {
                      display: flex;
                      flex-direction: row; 
                      margin-bottom: 0.25rem;
                      cursor: pointer;
                      text-decoration: none;
                      //flex-shrink: 0;
                      .Icon {
                        margin: auto 0rem;
                        color:  rgba($l7-frame-color , 1);
                        //flex-shrink: 0;
                      }
                      .Text {
                        margin: auto 0rem;
                        margin-left: 0.25rem;
                        font-size: 100%;
                        color:  rgba($l7-white-color , 1);
                        line-height: 20px;
                        //flex-shrink: 0;
                      }
                    }
                    .Address {
                      padding: 0.25rem 0rem;
                    }
                    .Tel {
                      border-radius: 2rem;
                      border : 1px solid rgba($l7-white-color , 1);
                      padding: 0.25rem 1rem;
                      width: fit-content;
                      margin-bottom: 1.50rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .Frame {
      position: absolute;
      z-index: 3;  
      top: 0px;
      bottom: 0px;
      left:0px;
      right: 0px;
    }
  }
  .Lower {
    display: flex;
    flex-direction: row;
    //justify-content: center;
    flex-shrink: 0;
    //background-color: rgba( $l7-frame-color, 0.5 );
    //min-height: 100px;
    transition: all 0.25s ease-in;
    position: relative;
    z-index: 5;
    overflow-y: hidden;
    overflow-x: auto;
    .Event {
      padding : 0rem 1rem;
      margin : 0.5rem auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .Box {
        margin : auto 0.25rem;
        transition: all 0.25s ease-in;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .Img {
          border-radius: 50%;
          height : 30px;
          width : 30px;
          border : 2px solid rgba($l7-frame-color , 1);
          background-color: rgba( $l7-dark-color, 1 );
          margin: 0rem auto;
        }
        .Title {
          font-size: 75%;
          color : rgba($l7-frame-color , 1);
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 80px;
          overflow: hidden;
          margin: 0 auto;
          margin-top: 0.25rem;
        }
        &:hover {
          transform: scale(1.25);
        }
      } 
    }
  }
  &.Show {
    z-index : 100;
    .Close {
      display: flex;
    }
    .Upper {
      width : auto;
      height : auto;
      overflow: initial;
    }
  }
  &.Hide {
    z-index : 13;
    top:auto;
    height: fit-content;
    background-color: rgba( $l7-dark-color, 0 );
    .Close {
      display: none;
    }
    .Upper {
      padding : 0rem;
      //width : 0rem;
      //height : 0rem;
      overflow: hidden;
      opacity: 0;
      .Image {
        max-height : 0;
      }
    }
    
  }
}

@media screen and (max-width: 951px)  {
  .Popup {
    .Upper {
      padding: 0rem 3rem;
      .Holder {
        .Image {
          max-width: 751px;
        }
        
      }
    }
  }
}

@media screen and (max-width: 751px)  {
  .Popup {
    .Upper {
      padding: 0rem 0.5rem;
      .Holder {
        .Image {
          max-width: 100%;
        }
        &.Flip {
          .Stores-Frame {
            .Mid-Panel {
              .Messages {
                .title {
                  //font-size: 175%;
                  //line-height: 27.5px;
                  //min-height: 27.5px;
                  //margin-top: 1.5rem;
                  //font-weight: bold;
                  //text-align: center;
                  //margin-bottom: 1.5rem;
                }
                .gallery {
                  .img {
                    width : calc( 100% - 0.5rem);
                  }
                }
              }
            }
            .Bottom-Panel {
              .Store-Group {
                .Brand-Holder {
                  .Logo {
                    width : 30px;
                    height: 30px;
                  }
                  .Shop-Holder {
                    .Brand-Name {
                      height: 40px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}