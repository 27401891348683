@import "../../public/style.scss";

.Shop {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background-color: rgba( $l7-white-color, 1 );
  z-index : 1;
  min-height: 100vh;
  width:100%;
  padding-bottom: 4rem;
  &.Show {
    z-index : 99;
    //overflow: hidden;
    //height: 100vh;
  }
  .Panel {
    display: flex;
    flex-direction: row;
    width : 951px;
    margin: 1rem auto;
    .Back {
      display: flex;
      flex-direction: row;
      background-color: transparent;
      color : rgba( $l7-dark-color, 0.75 );
      flex-shrink: 0;
      text-decoration: none;
      cursor: pointer;
      padding: 1rem 1rem;
      .Icon {
        height:20px;
        transition: all 0.25s ease-in;
      }
      .Text {
        font-size: 100%;
        transition: all 0.25s ease-in;
        margin-left: 0.5rem;
      }
      &:hover {
        color : rgba( $l7-frame-color, 1 );
      }
    }
    .Shops-Holder {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      //flex-wrap: wrap; 
      max-width: 100%;
      //overflow-x: auto;
      text-decoration: none;
      overflow-x: auto;
      padding:0rem 1rem;
      .Shop-Frame {
        display: flex;
        flex-direction: row;
        margin: auto 0.5rem;
        transition: all 0.25s ease-in-out;
        //max-width: 50px;
        width:auto;
        margin-bottom: auto;
        text-decoration: none;
        flex-shrink: 0;
        .Icon {
          width:30px;
          height:30px;
          border-radius: 50%;
          border: 2px solid rgba( $l7-frame-color, 1 );
          margin: 0px auto;
          margin-bottom: 0.25rem;
          display: flex;
          flex-direction: row;
          justify-content: center;
          object-fit: cover;
          &.Fa {
            width:20px;
            height:20px;
            padding : 5px;
            color : rgba( $l7-white-color, 1 );
            background-color: rgba( $l7-dark-color, 0.5 );
          }
        }
        .Text {
          font-size: 70%;
          color :rgba( $l7-dark-color, 0.75 );
          text-align: center;
          word-wrap: break-word;
          max-width: 80px;
          margin: auto 0rem;
          margin-left: 0.25rem;
        }
        &:hover {
          //.Icon {
            transform: scale(1.25);
          //}
        }
      }
    }
  }
  .Booking-Wrapper {
    display: flex;
    flex-direction: row;
    width: 951px;
    margin: 0rem auto;
    justify-content: flex-end;
    .Booking-Btn {
      cursor: pointer;
      align-self: baseline;
      padding : 0.25rem 1rem;
      //margin-top: calc( 0.65rem );
      border: 1px solid rgba( $l7-frame-color, 0.9 );
      color : rgba( $l7-frame-color, 1 );
      &:hover {
        background-color: rgba( $l7-frame-color, 1 );
        color : rgba( $l7-white-color, 1 );
      }
    }
  }
  .Upper {
    display: flex;
    flex-direction: row;
    width : 951px;
    margin: 2rem auto;
    .Left {
      display: flex;
      flex-direction: column;
      width : 320px;
      flex-shrink: 0;
      .Logo {
        width:210px;
        height: auto;
        //height:80px;
        margin : 0.5rem 0rem;
        //margin-top : auto;
        margin-bottom: 0rem;
        margin-top: 0rem;
      }
      .Badges {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        .Badge {
          font-size: 100%;
            background-color: rgba( $l7-frame-color, 1 );
            color: rgba( $l7-white-color, 1 );
            margin: auto 0rem;
            font-weight: bold;
            border-radius: 2rem;
            padding : 0.25rem 1rem;
            margin-right: 0.5rem;
            cursor: default;
            height: fit-content;
            margin-bottom: 0.25rem;
            &:last-child {
              margin-right: 0rem;
              //margin-bottom: auto;
            }
        }
      }
      .Address-Frame {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        text-align: center;
        width: fit-content;
        .Name {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          cursor: pointer;
          text-decoration: none;
          //margin: auto;
          .Icon {
            color : rgba( $l7-frame-color, 1 );
            height: 40px;
            margin: auto 0rem;
            margin-right: 0.5rem;
            transition: all 0.25s ease-in;
          }
          .Text {
            font-size: 150%;
            color :rgba( $l7-dark-color, 1 );
            margin: auto 0rem;
            font-weight: bolder;
            
          }
          &:hover {
            .Icon {
              transform: scale(125%);
            }
          }
        }
        .S-Detail {
          font-size: 110%;
          color :rgba( $l7-dark-color, 0.75 );
          //max-width: 200px;
          text-align: left;
          width: fit-content;
          //padding-right: 1rem;
          margin: 0rem auto;
        }
      }
      .Tel {
        margin: 1rem 0rem;
        color : rgba( $l7-frame-color, 1 );
        font-size: 125%;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        text-decoration: none;
        cursor: pointer;
        .Icon {
          margin: auto 0rem;
        }
        .Text {
          margin: auto 0rem;
          margin-left: 0.5rem;
        }
      }
      .Menu-Frame {
        display: flex;
        flex-direction: column;
        margin: 1rem 0rem;
        margin-bottom: 2rem;
        .Title {
          color : rgba( $l7-dark-color, 1 );
          font-size: 125%;
          font-weight: bold;
        }
        .Link {
          font-size: 125%;
          color : rgba( $l7-dark-color, 0.8 );
          text-decoration: none;
          cursor: pointer;
          transition: all 0.25s ease-in-out;
          &:hover {
            color : rgba( $l7-frame-color, 1 );
          }
        }
      }
      .Operation {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .Title {
          font-size: 125%;
          font-weight: bolder;
          
        }
        .Daytime {
          display: flex;
          flex-direction: column;
          font-size: 125%;
          //font-weight: bolder;
          color : rgba( $l7-dark-color, 0.8 );
          .Line {
            margin-bottom: 0.25rem;
          }
        }
      }
    }
    .Right {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      width:100%;
      margin-left: 1rem;
      .S-Cover {
        width: 100%;
        object-fit: contain;
        margin-bottom: auto;
        &.Mock {
          background-color: rgba( $l7-dark-color, 0.5 );
          color : rgba( $l7-white-color, 1 );
          font-size: 300%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          height: 400px;
        }
      }
    }
  }
  .Bottom {
    width : 951px;
    margin: auto;
    padding: 3rem 0rem;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .Holder {
      width:300px;
      height : 300px;
      margin :0.25rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      overflow: hidden;
      transition: all 0.25s ease-in-out;
      position: relative;
      z-index: 1;
      .Image {
        opacity: 1;        
        //width:300px;
        //height : 300px;
        flex-grow : 1;
        object-fit: cover;
        max-height: 100%;
        width: auto;
      }
      &:hover {
        opacity: 1;
        transform: scale(1.1);
        z-index: 2;
        box-shadow: 0px 0px 10px rgba( $l7-dark-color, 0.5 );
        cursor: pointer;
      }
    }
  }

  .Gallery {
    opacity : 0;
    z-index : -1;
    position: fixed;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    background-color: rgba( $l7-white-color, 0.9 );
    backdrop-filter: blur(2px);
    display: flex;
    flex-direction: column;
    padding : 1rem;
    transition: all 0.25s ease-in-out;
    .Controls {
      display: flex;
      flex-direction: row;
      width:951px;
      margin: 0rem auto;
      height:fit-content;
      flex-shrink: 0;
      .Expand, .Close {
        margin-right: auto;
        padding : 0.5rem;
        height: auto;
        font-size: 200%;
        color : rgba( $l7-dark-color, 0.5 );
        transition: all 0.25s ease-in-out;
        &:hover {
          color : rgba( $l7-dark-color, 1 );
        } 
      }
      .Expand { 
        display: none;
      }
      .Close {
        margin-left:auto;
        margin-right: 0rem;
      }
    }
    &.Show {
      opacity: 1;
      z-index :5;
    }
    .Holder {
      display : flex;
      flex-direction: row;
      flex-grow: 1;
      width:951px;
      margin: auto;
      .Left, .Right {
        height : auto;
        font-size: 200%;
        color : rgba( $l7-dark-color, 0.5 );
        transition: all 0.25s ease-in-out;
        flex-shrink: 0;
        &:hover {
          color : rgba( $l7-dark-color, 1 );
        } 
      }
      .Middle {
        flex-grow : 1;
        margin : 0rem auto;
        padding : 0rem 1.5rem;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .Image {
          position: absolute;
          z-index : -1;
          opacity: 0;
          width: calc(100% - 3rem);
          top:0px;
          left:1.5rem;
          right:1.5rem;
          max-height: calc( 100vh - 120px);
          transition: all 0.25s ease-in-out;
          transform: scale(0);
          &.Selected {
            position: relative;
            opacity: 1;
            z-index : 1;
            transform: scale(1);
          }
        }
      }
    }
  }


}

@media screen and (max-width: 951px)  {
  .Shop {
    padding-bottom: 4rem;
    .Panel {
      width: 100%;
    }
    .Upper {
      width:100%;
      flex-direction: column;
      .Left {
        margin :0rem auto;
        margin-bottom: 2rem;
        .Logo {
          margin: auto;
        }
        .Badges {
          //margin: auto;
          justify-content: center;
        }
        .Menu-Frame {
          width: 100%;
          .Title {
            margin: auto;
          }
          .Link {
            text-align: center;
            margin: auto;
          }
        }
        .Address-Frame {
          width: 100%;
          .Name {
            margin: auto;
          }
          .Detail {
            text-align: center;
            margin: auto;
            font-size: 110%;
          }
        }
        .Tel {
          text-align: center;
          margin: 1rem auto;
        }
        .Operation {
          text-align: center;
        }
      }
      .Right {
        margin-left: 0rem;
        max-height:400px;
        overflow: hidden;
        width:100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .S-Cover {
          width: 100%;
          //height: 100%;
          height: auto;
          object-fit: contain;
          margin: auto;
          &.Mock {
            height: 200px;
          }
        }
      }
    }

    .Bottom {
      width : 100%;
      .Holder {

        .Image {
          //margin :0.5rem 0.5rem;
        }
      }
    }

    .Gallery {
      .Controls {
        width:100%;

      }
      .Holder {
        width:100%;
      }
    }
  }
}

@media screen and (max-width: 751px)  {
  .Shop {
    .Bottom {
      width : 100%;
      .Image {
        //margin :0.5rem 2rem;
      }
    }
  }
}