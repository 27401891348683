@import "../../public/style.scss";

.Debug{
  display: flex;
  flex-direction: column;
  background-color: rgba( $l7-white-color, 1 );
  z-index : 1;
  height: 100vh;
  .D-Frame {
    margin: auto;
    display: flex;
    flex-direction: column;
    .D-Title {
      cursor: default;
      color: rgba( $l7-dark-color, 1 );
      font-size: 100%;
      text-align: center;
      margin-bottom: 2rem;
    }
    .Input {
      border-width: 0px;
      border-bottom: 1px solid rgba( $l7-dark-color, 0.25 );
      background-color: transparent;
      transition: all 0.25s ease-in;
      padding : 1rem;
      text-align: center;
      &:hover {
        background-color: rgba( $l7-dark-color, 0.1 );
      }
    }
    .Submit {
      transition: all 0.25s ease-in;
      background-color: rgba( $l7-frame-color, 1 );
      color :rgba( $l7-white-color, 1 );
      padding : 1rem 0.25rem;
      border-radius:  0.5rem;
      margin-top: 1rem;
      text-align: center;
      cursor: pointer;
      &:hover {
        background-color: rgba( $l7-frame-color-2, 1 );
      }
    }
  }
}

@media screen and (max-width: 951px)  {
}