@import "../../public/style.scss";

.Membership {
  display: flex;
  flex-direction: column;
  background-color: rgba( $l7-white-color, 1 );
  z-index : 1;
  min-height: 100vh;
  padding : 2rem 0rem;
  .Title {
    width: 951px;
    font-size: 225%;
    color : rgba( $l7-frame-color, 1 );
    font-weight: bold;
    text-align: center;
    margin: 2rem auto;
  }
  .Image {
    max-width: 951px;
    max-height: 500px;
    padding : 4rem 0rem;
    margin: auto;
  }
  .Remark {
    font-size: 90%;
    margin: auto;
    text-align: right;
  }
  .M-Wrapper {
    background-color: rgba( $l7-frame-color, 0.25 );
    color : rgba( $l7-dark-color, 0.75 );
    width : calc( 951px - 6rem);
    margin: 0rem auto;
    padding : 0rem 3rem;
    display: flex;
    flex-direction: column;
    .W-Title {
      color : rgba( $l7-dark-color, 1 );
      font-size: 150%;
      font-weight: bold;
      padding : 1.5rem 0rem;
      padding-bottom: 0.5rem;
    }
    .W-Message {
      font-size: 120%;
    }
    
    .W-Button {
      cursor: pointer;
      font-size: 100%;
      border: 2px solid rgba( $l7-frame-color, 1 );
      color : rgba( $l7-frame-color, 1 );
      border-radius: 4rem;
      padding: 0.5rem 3rem;
      width: fit-content;
      margin: 1rem auto;
      transition: all 0.25s ease-in;
      &:hover {
        border: 2px solid rgba( $l7-frame-color-2, 1 );
        color : rgba( $l7-white-color, 1 );
        background-color: rgba( $l7-frame-color-2, 1 );
      }
    }
    &:nth-child(2) {
      padding-top: 2rem;
    }
    &:nth-last-child(2) {
      padding-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 951px)  {
  .Membership {
    .Title {
      width:calc( 100% - 2rem);
    }
    .Image {
      max-width:calc( 100% - 2rem);
      padding : 4rem 1rem;
      max-height:300px;
    }
    .Remark {
      text-align: center;
      margin: auto 1rem;
    }
    .M-Wrapper {
      width : calc( 100% - 2rem);
      padding : 0rem 1rem;
    }
  }
}