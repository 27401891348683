@import "../../../public/style.scss";

.U-Login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .Logo {
    width : 150px;
    margin-bottom: 2rem;
  }
  .Holder {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    .Container {
      display: flex;
      flex-direction: column;
      padding : 0.5rem 1rem;
      position: relative;
      transition: all 0.25s ease-in;
      .Placeholder {
        transition: all 0.25s ease-in-out;;
        position :absolute;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color : rgba( $l7-dark-color, 0.75 );
        top: auto;
        bottom : 0.8rem;
        left:1rem;
        right:1rem;
        &.Small {
          font-size: 80%;
          top: 0rem;
          bottom : auto;
          align-items: flex-start;
        }
      }
      .Input {
        position: relative;
        z-index: 1;
        margin-top: 10px;
        transition: all 0.25s ease-in-out;
        border-width: 0px;
        border-bottom: 1px solid rgba( $l7-dark-color, 0.1 );
        padding: 0.5rem 1rem;
        height : 20px;
        border-radius: 0.5rem;
      }
      &:hover {
        .Placeholder {
          font-size: 80%;
          top: 0rem;
          bottom : auto;
          align-items: flex-start;
        }
        .Input {
          border: 1px solid rgba( $l7-dark-color, 0.05 );
        }
      }
    }
    .U-Login-Btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      cursor: pointer;
      transition: all 0.25s ease-in-out;
      background-color: rgba( $l7-frame-color, 1 );
      color : rgba( $l7-white-color, 1 );
      height: 40px;
      margin: 0rem 1rem;
      margin-top: 1rem;
      &:hover {
        background-color: rgba( $l7-frame-color-2, 1 );
      }
    }
  }

}

@media screen and (max-width: 951px)  {
  .Login {

  }
}

@media screen and (max-width: 751px)  {
  .Login {

  }
}