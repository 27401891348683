@import "../../public/style.scss";

.EShop {
  display: flex;
  flex-direction: column;
  background-color: rgba( $l7-white-color, 1 );
  z-index : 1;
  //min-height: 100vh;
  position: relative;
  height: calc( 100vh - 50px);
  overflow: auto;
  .E-Carousel{
    position: relative;
    width : 100%;
    flex-shrink: 0;
    height : var(--height);
    //height: 300px;
    background-color: rgba( $l7-dark-color, 1 );
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    &:hover {
      .E-Dots {
        opacity: 1;
      }
    }
    .E-Wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      top:0px;
      left:0px;
      height: 100%;
      width: 100%;
      z-index: 1;
      height: 100%;
      
      .EC-Image-Holder {
        //width : 100%;
        width: fit-content;
        height: 100%;
        position: absolute;
        //position: relative;
        z-index : 1;
        opacity: 1;
        transition: all 0.5s ease-in;
        overflow: hidden;
        box-shadow: 0px 0px 5px rgba( $l7-dark-color, 1 );
        flex-shrink: 0;
        background-color: rgba( $l7-accent-color-2, 1 );
        filter: brightness(0.5);
        .EC-Image {
          height: 100%;
        }
        &.Selected {
          //position: relative;
          z-index : 999!important;
          opacity: 1;
          //flex-grow: 1;
          filter: brightness(1)
        }
      }
    }
    .E-Dots {
      display: flex;
      flex-direction: row;
      position: relative;
      z-index : 99;
      background-color: rgba($l7-dark-color, 1);
      margin: auto;
      margin-bottom: 0rem;
      backdrop-filter: blur(5px);
      transition: all 0.25s ease-in;
      padding : 0.25rem 1rem;
      opacity: 0.25;
      
      .E-Dot {
        margin: 0rem 0.25rem;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        border: 2px solid rgba( $l7-accent-color-2, 0.75 );
        transition: all 0.25s ease-in;
        &.E-Dot-Selected {
          background-color: rgba( $l7-white-color, 1 );
        }
        //background-color: ;
      }
    }
  }
  .E-Content {
    display: flex;
    flex-direction: column;
    width : 100%;
    max-width: 1024px;
    align-items: center;
    margin: 0rem auto;
    padding-bottom: 4rem;
    .E-Filter {
      //height : 30px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width : calc( 100% - 2rem );
      padding : 1rem 1rem;
      border-bottom: 2px solid rgba( $l7-dark-color, 0.05 );
      position: sticky;
      top: 0px;
      z-index : 12;
      background-color: rgba( $l7-white-color, 1 );
      .E-F-Select {
        text-align: center;
        padding : 0.5rem 0.5rem;
        background-color: transparent;
        border-style: none;
        //border-bottom: 2px solid rgba( $l7-frame-color, 1 );
        color : rgba( $l7-frame-color, 1 );
        margin: 0.25rem 0.5rem;
        cursor: pointer;
      }
      .E-ShoppingCart {
        margin-left: auto;
        height: 100%;
        transition: all 0.25s ease-in;
        background-color: rgba( $l7-white-color, 1 );
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0rem 1rem;
        cursor: pointer;
        .Fa, .Num {
          transition: all 0.25s ease-in;
          color : rgba( $l7-frame-color, 1 );
          font-size: 125%;
        }
        .Num {
          margin-left: 0.5rem;
        }
        &:hover {
          background-color: rgba( $l7-frame-color, 1 );
          .Fa, .Num {
            color : rgba( $l7-white-color, 1 )
          }
        }
        
      }
    }
    .E-Total {
      display: flex;
      flex-direction: row;
      padding : 1rem 0rem;
      padding-top: 2rem;
      color : rgba( $l7-dark-color , 0.5 );
      font-weight: bold;
      cursor: default;
    }
    .E-Items-Holder {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      //align-items: center;
      justify-content: center;
      padding : 0rem 0rem; 
      padding-bottom: 2rem;
      min-height: 50vh;
      .E-Item {
        display: flex;
        flex-direction: column;
        position: relative;
        width : 300px;
        height : 300px;
        margin: 0.5rem;
        border: 2px solid rgba( $l7-dark-color, 0.05 );
        background-color: rgba( $l7-accent-color-2, 0.1 );
        z-index : 1;
        transition: all 0.25s ease-in;
        cursor: pointer;
        .E-I-Cover {
          width : 100%;
          &.Mask {
            width :100%;
            height: calc( 300px * 9 /16);
            background-color: rgba( $l7-dark-color, 1 );
            color : rgba( $l7-white-color, 1 );
            font-weight: bold;
            font-size: large;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
        .E-I-Title {
          font-size: 125%;;
          font-weight: bold;
          color : rgba( $l7-dark-color, 1 );
          padding: 0.5rem;
          justify-content: flex-start;
          display: flex;
          flex-direction: row;
          align-items: center;
          .E-Badge {
            font-size: 50%;;
            background-color: rgba( $l7-frame-color-2 , 1 ) ;
            padding : 0.25rem 0.5rem;
            margin-right: 0.25rem;
            color : rgba( $l7-white-color, 1 );
            border-radius: 0.75rem;
          }
        }
        .E-I-Desc {
          display: none;
          white-space: pre;
          font-size: 80%;
          font-weight: bold;
          color: rgba(var(--l7-dark-color), 0.5);
          padding: 0.25rem 0.75rem;
          overflow: hidden;
          height: 45px;
          text-overflow: ellipsis;
          opacity: 0;
        }
        .E-I-Price {
          display: flex;
          flex-direction: column;
          padding : 0.5rem 0.5rem;
          font-family: system-ui;
          flex-grow: 1;
          justify-content: center;
          .Original {
            font-size: 90%;
            font-weight: bolder;
            color : rgba( $l7-dark-color, 0.25 );
            text-decoration: line-through;
          }
          .Price {
            font-size: 150%;
            font-weight: bolder;
            color : rgba( $l7-frame-color, 1 );
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .Appendix {
              font-size: 60%;
              opacity: 0.8;
              font-weight:initial;
              padding-right: 0.5rem;
            }
          }
        }
        .Soldout-Text {
          position: absolute;
          top : 0px;
          left: 0px;
          z-index: 10;
          bottom: 0px;
          right: 0px;
          display: none;
          justify-content: center;
          flex-direction: column;
          align-items: center;
        }
        &.Soldout {
          .E-Carrier {
            filter: blur(1px) grayscale(1);
            //background-color: rgba( $l7-dark-color , 0.5 );
          }
          .Soldout-Text {
            display: flex;
            color : rgba( $l7-frame-color , 1 );
            font-size: 300%;
            font-weight: bolder;
            background-color: rgba( $l7-accent-color , 0.5 );
            .Text {
              letter-spacing: 0.25rem;
              //transform: rotate(-30deg);
            }
            
          }
        }
        &:hover:not(.Soldout) {
          z-index: 5;
          transform: scale(1.05);
          box-shadow: 0px 0px 5px rgba( $l7-dark-color, 0.05 );
        }
      }
    }
    .E-Items-Pagnation {
      padding : 1rem 0rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .E-Page {
        color : rgba( $l7-dark-color , 0.25 );
        padding : 0.25rem 0.5rem;
        padding-bottom: 2rem;
        cursor: pointer;
        &.Selected {
          color : rgba( $l7-accent-color , 1 );
          font-weight: bold;
        }
      }
    }
  }
  &.Lock {
    //height: 0px;
    overflow: hidden;
    //min-height: 0px;
  }
}

@media screen and (max-width: 951px)  {
  .EShop {
  }
}

@media screen and (max-width: 751px)  {
  .EShop {

    .E-Content {
      .E-Filter {
        .E-F-Select {
          margin : 0.25rem;
          padding : 0.25rem;
        }
      }
    }
  }
}