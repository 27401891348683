@import "../../public/style.scss";

.Cover {
  display: flex;
  flex-direction: column;
  flex-grow : 1;
  background: rgba( $l7-white-color, 1 );
  z-index : 1;
  .Video {
    height: calc( 100vh - 80px);
    object-fit: cover;
  }
  .Padding {
    padding: 3rem 0rem;
  }
  .Brand-Container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3rem 1rem;
    max-width: 951px;
    margin: auto;
    margin-top: 3rem;
    .Box {
      position: relative;
      width:calc( 300px - 8px);
      height:calc( 300px - 8px);
      display: flex;
      flex-direction: column;
      margin : 0.5rem;
      justify-content: center;
      border : 2px solid rgba( $l7-dark-color, 0.3 );
      border-color: rgba(189,189,189, 1);
      transition: all 0.25s ease-in;
      cursor: pointer;
      overflow: hidden;
      .Img {
        width:auto;
        height: auto;
        //max-width:70%;
        width: 200px;
        height: auto;
        //max-height:60%;
        margin: auto;
        transition: all 0.25s ease-in;
      }
      .Cover-Frame {
        top:0px;
        left:0px;
        position: absolute;
        width:100%;
        height:100%;
        opacity: 0;
        
        transition: all 0.25s ease-in;
        display: flex;
        flex-direction: row;
        justify-content: center;
        overflow: hidden;
        
        .Cover {
          object-fit: cover;
          width:auto;
          height:100%;
          z-index : -1;
          transform: scale(1.2);
          filter: blur(2px);
        }
        .Cover-M {
          width:100%;
          height:100%;
          text-align: center;
          display: flex;
          z-index : 1;
          flex-direction: column;
          justify-content: center;
          //padding-top: 3rem;
          filter: blur(1px);
          font-size: 250%;
          color : rgba( $l7-white-color, 0.8 );
          transform: scaleX(1.1);
        }
      }
      .Text-Frame {
        
        opacity: 0;
        transition: all 0.25s ease-in;
        transform: scaleX(1);
        
        position: absolute;
        z-index : 2;
        margin: auto;
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .Name {
          font-size: large;
          color: rgba( $l7-white-color, 1 );
          margin: auto;
          font-weight: bolder;
          margin-top: auto;
        }
        .Badges {
          display: flex; 
          flex-direction: row;
          margin-bottom: auto;
          margin: 1rem auto;
          margin-bottom: auto;
          flex-wrap: wrap;
          justify-content: center;
          .Badge {
            font-size: small;
            background-color: rgba( $l7-frame-color, 1 );
            color: rgba( $l7-white-color, 1 );
            margin: auto 0rem;
            font-weight: bold;
            border-radius: 2rem;
            padding : 0.25rem 0.5rem;
            margin: 0.25rem 0.25rem ;
            //margin-right: 0.5rem;
            
            &:last-child {
              margin-right: 0rem;;
            }
          }
        }
      }
      &:hover {
        background-color: rgba( $l7-dark-color, 0.5 );
        //backdrop-filter: blur(1px);
        //border : 4px solid rgba( $l7-dark-color, 0.1 );
        .Img {
          transform: scale(0.5);
          opacity: 0;
        }
        .Cover-Frame {
          opacity: 1;
          //filter: blur(1px);
          .Cover {
            filter: blur(1px);
          }
          .Cover-M {
            //filter: blur(1px);
          }
        }
        .Text-Frame {
          opacity: 1;
        }
      }
    }
  }
  .Cover-Message-Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 951px;
    margin: auto;
    cursor: default;
    padding-bottom: 8rem;
    padding : 4rem 0rem;
    .Container {
      display: flex;
      flex-direction: column;
      padding : 1rem 0rem;
      .Title {
        font-size: 225%;
        color : rgba( $l7-frame-color, 1 );
        font-weight: bolder;
        margin: 0rem auto;
        padding : 0rem;
        padding-top: 1rem;
        text-align: center;
      }
      .Message {
        max-width: 751px;
        color : rgba( $l7-dark-color, 0.75 );
        margin: 0rem auto;
        margin-bottom: 0rem;
        text-align: center;
        font-size: 120%;
        line-height: 1.75rem;
        padding : 1rem;
      }
      .Image-Holder {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 2rem 2rem;
        .Image {
          //max-width: 33%;

        }
      }
    }
  }
}


@media screen and (max-width: 951px)  {
  .Cover {
    .Video {
      height: auto;
      width: 100%;
    }
    .Brand-Container {
      margin-top: 0rem;
      padding-bottom: 1rem;
    }
    .Cover-Message-Wrapper {
      padding-top : 0rem;
      .Container {
        //padding-bottom: 0rem;
        .Title {
          padding-top: 2rem;
        }
        .Image-Holder {
          padding: 0rem 2rem;
        }
      }
    }
  }
}