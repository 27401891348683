@import "../../public/style.scss";

.Footer {
  display: flex;
  flex-direction: column;
  background-color: rgba( $l7-frame-color-2, 1 );
  z-index : 10;
  //min-height:100px;
  padding : 2rem 0rem ;
  padding-bottom: 4rem;
  //position: sticky;
  //bottom: 0px;
  //margin-top: auto;
  .Footer-Top-Frame {
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    padding : 0rem 0rem ;
    padding-bottom: 0rem;
    width: 951px;
    margin: 0rem auto;
    .Lefts {
      display: flex;
      flex-direction: row;
      .Left {
        display: flex;
        flex-direction: column;
        padding : 1rem 1rem;
        margin: 0rem 1rem;
        margin-right: 1rem;
        width: fit-content;
        //min-width: 50px;
        min-height: 200px;
        .Logo {
          margin: auto 0rem;
          margin-top: 0rem;
          max-width: 150px;
          padding : 0rem 0rem;
          padding-left: 0rem;
        }
        .Link, .Title {
          padding : 0.25rem 0.25rem ;
          color : rgba( $l7-white-color, 1 );
          font-size: 100%;
          min-width: 80px;
          cursor: pointer;
          text-decoration: none;
          &:first-child {
            padding-bottom: 2rem;;
          }
          &.None {
            cursor: default;
          }
        }
        .Link {
          text-decoration: none;
          color : rgba( $l7-white-color, 1 ); 
        }
        &:first-child {
          margin-left: 0rem;
        }
        &:last-child {
          margin-right: auto;
        }
      }
    }
    .Right {
      display: flex;
      flex-direction: row;
      padding: 1rem 1rem;
      margin-left: 1rem;
      margin-bottom: 3rem;
      cursor: default;
      .Title-Frame {
        display: flex;
        flex-direction: row;
        .Icon-Wrapper {
          display: flex;
          flex-direction: column;
          background-color: rgba( $l7-white-color, 1 );
          padding : 0.5rem;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          justify-content: center;
          .Icon {  
            color : rgba( $l7-dark-color, 1 );
            width:20px;
            height:20px;
            margin: auto;
          }
        }
        .T-Wrapper {
          display: flex;
          flex-direction: column;
          margin-left: 1rem;
          .Text {
            color :rgba( $l7-white-color, 1 );
            font-size: 100%;
          }
          .Policy {
            color :rgba( $l7-white-color, 0.8 );
            font-size: 100%;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
      .Frame {
        display: flex;
        flex-direction: row;
        justify-content: center;
        //padding-bottom: 1rem;
        margin: auto;
        margin-right: 0rem;
        .Name {
          display: none;
          font-size: 120%;
          padding:0.25rem 0rem;
          color :rgba( $l7-white-color, 1 );
        }
        .Input {
          background-color: rgba( $l7-white-color, 1 );
          padding: 0.25rem 1rem;
          min-width: calc( 300px - 2rem);
          border-width: 0px;
          border-radius: 0.5rem;
          height : calc( 40px - 0.5rem );
          border-top-right-radius: 0rem;
          border-bottom-right-radius: 0rem;
        }
        &:last-child {
          padding-bottom: 0rem;
        }
        .Signup {
        
          cursor: pointer;
          //padding : 1rem 3.5rem;
          //margin : 1rem auto;
          position: relative;
          display: flex;
          flex-direction: row;
          height:40px;
          min-width : 80px;
          margin : auto 0rem;
          
          .Text {
            color : rgba( $l7-white-color, 1 );
            margin: auto;
          }
          .SFrame {
            transition: all 0.25s ease-in-out;
            position: absolute;
            z-index: -1;
            top:0px;
            left:0px;
            height: 100%;
            width :100%;
            background-color: rgba( $l7-frame-color, 1 );
            border-radius: 0.5rem;
            opacity: 0.8;
            border-top-left-radius: 0rem;
            border-bottom-left-radius: 0rem;
          }
          &:hover {
            .SFrame {
              opacity: 1;
            }
          }
        }
      }
      
    }

  }
  .Footer-Bottom-Frame {
    display: flex;
    flex-direction: row;
    margin-top:2rem;
    padding : 1rem 0rem;
    width: 951px;
    margin: 0rem auto;
    justify-content: center;
    .Copyright {
      padding : 0rem 0rem ;
      font-size: 90%;
      color : rgba( $l7-white-color, 1 );
      padding-right : 0rem; 
    }
    .Terms-Frame {
      flex-direction: row;
      display: flex;
      .Term-Btn {
        text-decoration: none;
        &:before {
          content : "|";
          padding : 0rem 0.5rem;
        }
        padding : 0rem 0rem;
        font-size : 90%;
        color : rgba( $l7-white-color, 1 );
      }
    } 
  }
  &.Disabled {
    display: none;
  }
}

@media screen and (max-width: 951px)  {
  .Footer {
    .Footer-Top-Frame {
      width: calc( 100% - 2rem);
      padding : 1rem 1rem;
      justify-content: left;
      //flex-direction: column;
      //flex-basis: 50%;
      //flex-wrap: wrap;
      .Lefts{
        .Left {
          padding : 0rem 0rem;
          &:first-child {
            display: none;
          }
          &:nth-child(2) {
            margin-left: auto;
          }
          margin: 0rem 0rem;
          margin-right: 1rem;
        }
      }
      .Right {
        margin: auto;
        margin-top: 3rem;
        flex-direction: column;
          //margin-left: 0rem;
          //padding :1rem 4rem;
        .Frame {
          margin-left: 0rem;
          margin-top: 1rem;
            .Input {
              min-width:auto;
              flex-grow: 1;
              //max-width: calc( 300px - 0rem);
              //border-radius: 0rem;
              //border-top-left-radius: 0.5rem;
              //border-top-right-radius: 0.5rem;
            }
            .Signup {
              .SFrame {
                //border-radius: 0rem;
                //border-bottom-left-radius: 0.5rem;
                //border-bottom-right-radius: 0.5rem;
              }
              margin-bottom: 3rem;
            }
        }
        
      }
    }
    .Footer-Bottom-Frame {
      width: calc( 100% - 2rem);
      padding : 1rem 1rem;
      flex-direction: column;
      justify-content: center;
      .Copyright {
        font-size: 80%;
        text-align: center;
      }
      .Terms-Frame{
        justify-content: center;
        .Term-Btn {
          &:first-child {
            &::before {
              content : "";
              padding : 0px;
            }
          } 
        }
      }
    }
  }
}