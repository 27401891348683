@import "../../../public/style.scss";

.PointHistory {
    height: calc(100vh - 35px);
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100vw;
    //justify-content: center;
    //align-items: center;
    //flex-grow: 1;
    .P-Title {
      width: 100vw;
      font-size: 150%;
      font-weight: bolder;
      color : rgba($l7-frame-color-sub, 1);
      text-align: center;
      //margin: 1rem auto;
      padding : 0.5rem 0rem;
      flex-shrink: 0;
      position: sticky;
      top: 0px;
      left:0px;
      background-color: rgba($l7-white-color, 1);
      z-index : 6;
    }
    .Row {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      //max-width: 751px;
      cursor: default;
      margin: 0rem auto;
      &.Title {
        font-weight: bold;
        background-color: rgba($l7-frame-color-2-sub, 1);
        border-bottom: 1px solid rgba($l7-dark-color, 0.05);
        position: sticky;
        top:47.5px;
        z-index: 5;
        //padding: 0.5rem 0rem;
        position: sticky;
        //top: calc( 70px +  1rem) ;
        .Td {
          font-size: 100%;
          //padding : 0rem 0rem;
          
        }
      }
      .Td {
        width: 100px;
        text-align: center;
        font-size: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding : 0.5rem 0.5rem;
        border-left: 1px solid rgba($l7-dark-color, 0.05);
        word-break: break-word;
        &:first-child {
          border-left-width: 0px;
        }
      }
    }
    .P-Holder {
      display: flex;
      flex-direction: column;
      //justify-content: center;
      justify-content: flex-start;
      //flex-grow: 1;
      
      padding-bottom: 4rem;
    
      
    }
}

@media screen and (max-width: 951px)  {
  .PointHistory {

  }
}

@media screen and (max-width: 751px)  {
  .PointHistory {

  }
}