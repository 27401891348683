@import "../../public/style.scss";

.FB {
  display: flex;
  background-color: rgba( $l7-white-color, 1 );
  z-index : 1;
  min-height: 100vh;
  //max-width: 1024px;
  flex-direction: row;
  justify-content: center;
  .Content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1024px;
    justify-self: auto;
    padding: 4rem 0rem;
    .Box {
      width:300px;
      margin: 0.5rem;
      padding:0rem;
      border:1px solid rgba( $l7-dark-color, 0.1 );
      height: fit-content;
      flex-direction: column;
      display: flex;
      transition: all 0.25s ease-in;
      position: relative;
      z-index: 1;
      overflow: hidden;
      text-decoration: none;
      padding-bottom:1.5rem;
      cursor: pointer;
      //background-color: rgba( $l7-dark-color, 1 );
      .Image-Holder {
        width:100%;
        max-height: 300px;
        display: flex;
        flex-direction: row;
        margin: auto;
        background-color: rgba( $l7-dark-color, 1 );
        .Cover-Img {
          width: 100%;
          min-height:300px;
          object-fit: cover;
          transition: all 0.25s ease-in;
          opacity: 0.75;
        }
      }
      .Message {
        background-color: rgba( $l7-white-color, 1 );
        padding:1.5rem 1.5rem;
        padding-bottom: 0rem;
        color : rgba( $l7-dark-color, 0.9 );
        max-height: 200px;
        overflow: hidden;
        //display: flex;
        //flex-direction: column;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        .Title {
          font-size: 150%;
          font-weight: bolder;
        }
        .Body {
          flex-grow: 1;
          p {
            color :rgba( $l7-dark-color, 0.75 );
          }
        }
      }

      &:hover {
        transform: scale(1.05);
        z-index: 2;
        box-shadow: 0px 0px 10px rgba( $l7-dark-color, 0.5 );
        .Cover-Img {
          //transform: scale(1.1);
        }
      }
    }
  }
}

@media screen and (max-width: 951px)  {
}