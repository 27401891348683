@import "../../public/style.scss";

.Catering {
  display: flex;
  flex-direction: column;
  background-color: rgba( $l7-white-color, 1 );
  z-index : 1;
  //min-height: 100vh;
  position: relative;
  height: calc( 100vh - 0px);
  overflow: auto;
  position: sticky;
  top : 45px;
  height : calc( 100vh - 45px);
  .CA-Shops{
    position: sticky;
    top:0px;
    width : 100%;
    flex-shrink: 0;
    //z-index : 9;
    z-index : 15;
    //height: 300px;
    background-color: rgba( $l7-accent-color-2, 1 );
    //background-color: rgba( $l7-accent-color-3, 1 );
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.25s ease-in;
    height: 89px;
    .CA-S-Holder {
      display: flex;
      flex-direction: row;
      padding:0.25rem;
      overflow-y: hidden;
      overflow-x: auto;
      .CA-S-Brand, .CA-S-Shop {
        position: relative;
        z-index :1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width : calc(25px + 0rem + 0rem + 4px);
        height: calc(25px + 0rem + 0rem + 4px);
        transition: all 0.25s ease-in;
        margin: 0.5rem;
        flex-shrink: 0;
        cursor: pointer;
        ///overflow: hidden;
        .CA-S-Logo {
          //width: 100%;
          //height: 100%;
          transition: all 0.25s ease-in;
          width : auto;
          height: 100%;
          background: rgba( $l7-white-color , 1 );
          //padding : 0.25rem;
          border-radius: 50%;
          border : 2px solid rgba( $l7-frame-color , 1 );
          
          display: flex;
          flex-direction: column;
          justify-content: center;
          overflow: hidden;
          .CA-Image {
            margin: 0.25rem;
            width: calc( 100% - 0.5rem);
            height: calc( 100% - 0.5rem);
            background-size: auto 100%;
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 125%;
            font-weight: bold;
            transition: all 0.25s ease-in;
          }
        }
        .CA-S-Name {
          font-size: 100%;
          width: 100px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          //word-break: break-all;
          word-wrap: break-word;
        }
        &.CA-S-Shop {
          font-size: smaller;
          //width :100px;
          //border-top: 1px solid rgba( $l7-frame-color , 0.5 );
          width: fit-content;
          height : 20px;
          padding : 0.25rem 0.5rem;
          text-align: center;
          margin: 0rem;
          &:hover {
            background-color: rgba( $l7-frame-color , 0.5 );
            color : rgba( $l7-white-color , 1 );
          }
          &.Selected {
            background-color: rgba( $l7-frame-color , 1 );
            color : rgba( $l7-white-color , 1 )
          }
        }
        &:hover:not(.CA-S-Shop) {
          transform: scale(1.2);
          z-index: 5;
        }
        &.Selected:not(.CA-S-Shop) {
          //transform: scale(1.25);
          z-index: 4;
          background-color: rgba( $l7-frame-color-2 , 1 );
          box-shadow: 0px 0px 5px rgba( $l7-frame-color-2 , 1 );

          position: sticky;
          left : 0.5rem;
          right : 0.5rem;
          z-index : 10;
          .CA-S-Logo {
            //box-shadow: 0px 0px 5px rgba( $l7-frame-color-2 , 0.5 );
            border-color: rgba( $l7-frame-color-2 , 1 );
          }
        }
      }
      &:first-child {
        border-bottom: 1px solid rgba( $l7-frame-color , 0.1 );
        z-index : 10;
      }
    }
    &.Picked {
      position: absolute;
      height: 200px;
      bottom: 0px;
      //height: 50vh;
      box-shadow: 0px 0px 20px rgba( $l7-dark-color , 0.25 );
      z-index : 15;
      background-color: rgba( $l7-accent-color-2 , 0.8 );
      backdrop-filter: blur(4px);
      .CA-S-Holder {
        .CA-S-Brand, .CA-S-Shop {
          width : calc(90px + 0rem + 0rem + 4px);
          height: calc(90px + 0rem + 0rem + 4px);
          .CA-S-Logo {
              //width : 80px;
              //height: 80px;
              .CA-Image {
                //width : 40px;
                //height: 40px;
              }
          }
          &.CA-S-Shop {
            width : calc(30px + 0rem + 0rem + 0px);
            height: calc(30px + 0rem + 0rem + 0px);
            font-size: smaller;
            //width :100px;
            //border-top: 1px solid rgba( $l7-frame-color , 0.5 );
            width: fit-content;
            height : 30px;
            padding : 0.25rem 0.5rem;
            text-align: center;
            margin: 0rem;
            &:hover {
              background-color: rgba( $l7-frame-color , 0.5 );
              color : rgba( $l7-white-color , 1 );
            }
            &.Selected {
              background-color: rgba( $l7-frame-color , 1 );
              color : rgba( $l7-white-color , 1 )
            }
            font-size: 125%;
          }
          &.Selected:not(.CA-S-Shop) {
            
            .CA-S-Logo {

            }
          }
        }
      }
    }
  }
  .CA-Content {
    display: flex;
    flex-direction: row;
    width : 100%;
    max-width: 1024px;
    //align-items: center;
    margin: 0rem auto;
    flex-grow: 1;
    overflow: hidden;
    //padding-bottom: 4rem;
    //top : calc( 30px + 1rem + 1rem + 0.5rem + 0.5rem + 2px + 0.5rem + 2rem );
    //height: calc( 100vh - 30px - 1rem - 1rem - 0.5rem - 0.5rem - 2px - 0.5rem - 2rem );
    .CA-Menu {
      //height : 30px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
     // width : calc( 100% - 2rem );
      //width : calc(100px - 1rem);
      min-width: calc(100px - 1rem - 1px);
      padding : 1rem 0.5rem;
      border-right: 1px solid rgba( $l7-accent-color-2, 1 );
      position: sticky;
      left : 0px;
      z-index : 12;
      background-color: rgba( $l7-white-color, 1 );
      //flex-grow: 1;
      height: calc( 100% - 2rem);
      overflow: auto;
      //height: calc( 100vh - 30px - 1rem - 1rem - 0.5rem - 0.5rem - 2px - 0.5rem );
      .CA-Menu-List {
       padding : 1rem 0rem;
       border-bottom: 1px solid rgba( $l7-accent-color-2 , 0.5 );
       text-align: center;
       display: flex;
       flex-direction: column;
       cursor: pointer;
       transition : all 0.25s ease-in;
       justify-content: center;
       align-items: center;
       position: relative;
       z-index : 1;
       img {
        height :30px;
        width: fit-content;
        width : 30px;
        border-radius: 0.25rem;
       }
       span {

       }
       &:hover {
        background-color: rgba( $l7-accent-color-2 , 1 );
       }
       &.Selected {
        background-color: rgba( $l7-white-color , 1 );
        z-index : 2;
        transform: scale(1.05);
        box-shadow: 0px 0px 5px rgba( $l7-accent-color-2 , 1 );
        span {
          //color :rgba($l7-white-color,1);
        }
       }
      }
    }
    .CA-Total {
      display: flex;
      flex-direction: row;
      padding : 1rem 0rem;
      padding-top: 2rem;
      color : rgba( $l7-dark-color , 0.5 );
      font-weight: bold;
      cursor: default;
    }
    .CA-Items-Holder {
      display: flex;
      flex-direction: column;
      //flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      padding : 0rem 0rem; 
      padding-bottom: 2rem;
      min-height: 50vh;
      flex-grow: 1;
      min-width: calc( 100% - 100px );
      overflow: auto;
      .CA-Item {
        display: flex;
        flex-direction: row;
        position: relative;
        //width : 300px;
        //height : 300px;
        width : calc(100% - 0px - 0.5rem);
        height : 80px;
        margin: 0.25rem;
        //border: 2px solid rgba( $l7-dark-color, 0.05 );
        border-bottom: 2px solid rgba( $l7-accent-color-2, 0.25 );
        //background-color: rgba( $l7-accent-color-2, 0.1 );
        background-color: rgba( $l7-white-color, 1 );
        z-index : 1;
        transition: all 0.25s ease-in;
        cursor: pointer;
        .CA-Carrier {
          //filter: blur(1px) grayscale(1);
          display: flex;
          flex-direction: row;
          flex-grow: 1;
          width: 100%;
          overflow: hidden;
          align-items: center;
          .CA-I-Cover {
            //width : 100%;
            //height : calc( 100% - 2px);
            border: 2px solid rgba( $l7-accent-color-2 , 0.25 );
            max-width: 80px;
            height: fit-content;
            align-self: center;
            height: 80px;
            width: 80px;
            &.Mask {
              height : calc( 80px - 0.0rem);
              width: calc( 80px - 0.0rem);
              //width :100%;
              //height: calc( 300px * 9 /16);
              background-color: rgba( $l7-dark-color, 1 );
              color : rgba( $l7-white-color, 1 );
              font-weight: bold;
              font-size: large;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              //padding: 0.25rem;
            }
          }
          .CA-I-Title {
            font-size: 100%;
            //font-weight: bold;
            color : rgba( $l7-dark-color, 1 );
            margin: 0.5rem;
            justify-content: flex-start;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            white-space: pre-wrap;
            overflow: hidden;
            
            .CA-Badge {
              font-size: 50%;;
              background-color: rgba( $l7-frame-color-2 , 1 ) ;
              padding : 0.25rem 0.5rem;
              margin-right: 0.25rem;
              color : rgba( $l7-white-color, 1 );
              border-radius: 0.75rem;
            }
            pre {
              flex-shrink: 1;
              text-overflow: ellipsis;
              white-space: wrap;
              margin: 0px;
              line-height: 100%;
              font-size: 100%;
              font-family: inherit;
            }
          }
          .CA-I-Desc {
            display: none;
            white-space: pre;
            font-size: 80%;
            font-weight: bold;
            color: rgba(var(--l7-dark-color), 0.5);
            padding: 0.25rem 0.75rem;
            overflow: hidden;
            height: 45px;
            text-overflow: ellipsis;
            opacity: 0;
          }
          .CA-I-Price {
            display: flex;
            flex-direction: column;
            padding : 0.5rem 0.5rem;
            font-family: system-ui;
            flex-grow: 1;
            justify-content: center;
            flex-shrink: 0;
            .Original {
              font-size: 90%;
              //font-weight: bolder;
              color : rgba( $l7-dark-color, 0.25 );
              text-decoration: line-through;
              text-align: right;
            }
            .Price {
              font-size: 125%;
              //font-weight: bolder;
              color : rgba( $l7-frame-color, 1 );
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
              .Appendix {
                font-size: 60%;
                opacity: 0.8;
                font-weight:initial;
                padding-right: 0.5rem;
              }
              &.Soldout {
                margin-left: auto;
                background-color: red;
                padding: 0rem 0.5rem;
                span {
                  color : rgba( $l7-white-color , 1 );
                }
              }
            }
          }
          .Soldout-Text {
            position: absolute;
            top : 0px;
            left: 0px;
            z-index: 10;
            bottom: 0px;
            right: 0px;
            display: none;
            justify-content: center;
            flex-direction: column;
            align-items: center;
          }
        }
        &.Soldout {
          .CA-Carrier {
            filter: blur(1px) grayscale(1);
            //background-color: rgba( $l7-dark-color , 0.5 );
          }
          .Soldout-Text {
            display: flex;
            color : rgba( $l7-frame-color , 1 );
            font-size: 300%;
            font-weight: bolder;
            background-color: rgba( $l7-accent-color , 0.5 );
            .Text {
              letter-spacing: 0.25rem;
              //transform: rotate(-30deg);
            }
            
          }
        }
        &:hover:not(.Soldout) {
          z-index: 5;
          background-color: rgba( $l7-accent-color-2, 0.25 );
          //transform: scale(1.01);
          //box-shadow: 0px 0px 5px rgba( $l7-dark-color, 0.05 );
        }
      }
      .No-Item {
        text-align: center;
        padding : 1rem 0rem;
        margin: 0rem auto;
      }
    }
    .CA-Items-Pagnation {
      padding : 1rem 0rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .CA-Page {
        color : rgba( $l7-dark-color , 0.25 );
        padding : 0.25rem 0.5rem;
        padding-bottom: 2rem;
        cursor: pointer;
        &.Selected {
          color : rgba( $l7-accent-color , 1 );
          font-weight: bold;
        }
      }
    }
  }
  .Masonry-Wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .Masonry {
      cursor: pointer;
      height : calc( 150px - 2px);
      width: calc( 50% - 2px);
      background-position: center;
      background-size: cover;
      //margin-bottom: 0.25rem;;
      position: relative;
      background-color: rgba( $l7-dark-color, 1 );
      transition: all 0.25s ease-in;
      margin: 1px;
      &:first-child {
        margin-top: 0px;
      }
      .Frame {
        position: absolute;
        z-index : 2;
        top:0px;
        width: 100%;
        height: 100%;
        left:0px;
        background-color: rgba( $l7-white-color , 0.75 );
        backdrop-filter: blur(4px);
        transition: all 0.25s ease-in;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .Logo {
          height : 100px;
        }
        .Name {
          color : rgba( $l7-dark-color , 1 );
          font-size: 125%;
        }
      }
      &:hover {
        .Frame {
          background-color: rgba( $l7-accent-color-2 , 0.75 );
        }
        //transform: scale(1.05);
      }
      &.Large {
        height: calc(200px - 2px);
        width :100%;
        .Frame {
          .Logo {
            height : 125px;
          }
        }
      }
    }
  }
  .M-Cover {
    flex-grow: 1;
    //background-color: rgba( $l7-frame-color-2, 1 );
    background-color: rgba($l7-frame-color-2, 1);
      //background: rgb(2,0,36);
    background: linear-gradient(0deg, rgba($l7-white-color,1) 0%, rgba($l7-accent-color-3,1) 50%, rgba($l7-frame-color-2,1) 100%);
    z-index :5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;
    overflow: hidden;
    background-position: center;
    background-size: cover;

    .M-Message {
      backdrop-filter: blur(2px);
      position: relative;
      z-index :2;
      color : rgba( $l7-accent-color-2 , 1 );
      font-size: 150%;
      text-shadow: 0px 0px 5px rgba( $l7-dark-color , 1 ); 
      background-color: rgba( $l7-dark-color , 0.75 );
      width: 100%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .M-C-Logo {
        width : 100px;
        margin-top: auto;
      }
      h4 {
        margin-bottom: auto;
      }
    }
    .M-C-Bg {
      position:absolute;
      filter: blur(2px);
      opacity: 0.75;
      z-index :1;
      margin: auto;
    }
  }
  .CA-Order-Btn {
    cursor: pointer;
    //position: fixed;
    //bottom: 0rem;
    //left : 0rem;
    //right : 0rem;
    height: 40px;
    z-index : 15;
    //background-color: rgba( $l7-frame-color, 1 );
    background-color: rgba( $l7-accent-color-3, 1 );
    box-shadow: 0px 0px 5px rgba( $l7-dark-color , 0.25 );
    padding: 0.5rem 0.5rem;
    color : rgba( $l7-white-color , 1 );
    display: flex;
    flex-direction: column;
    //justify-content: center;
    //align-items: center;
    transition: all 0.25s ease-in;
    .Top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1;
      span {

      }
      .price {
        margin: 0rem;
        margin-left: auto;
        font-size: 125%;
      }
    }
    .Bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      p {
        margin: 0rem;
        font-size: 50%;
        opacity: 0.75;
        color : rgba( $l7-white-color , 1 );
        &:last-child {
          margin-left: 0.5rem;
        }
        &:first-child {
          margin-left: 0rem;
        }
        a {
          color : rgba( $l7-white-color , 1 );
        }
      }
    }
    &:hover {
      //background-color: rgba( $l7-accent-color-3, 1 );
      background-color: rgba( $l7-frame-color-2, 1 );
    }
  }
  &.Locked {
    //height: 0px;
    overflow: hidden;
    //min-height: 0px;
  }
}

@media screen and (max-width: 951px)  {
  .Catering {
    height : calc( 100vh - 60px);
  }
}

@media screen and (max-width: 751px)  {
  .Catering {
    top : 60px;
    height : calc( 100vh - 60px);
    .CA-Content {
      .CA-Filter {
        .CA-F-Select {
          margin : 0.25rem;
          padding : 0.25rem;
        }
      }
    }
  }
}