@import "../../public/style.scss";

.GiftCard {
  position: relative;
  height : 100%;
  width : calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  transition: all 0.25s ease-in-out;
  background-color: transparent;
  padding: 0rem 1rem;
  padding-bottom: 2rem;
  min-height: calc(100vh - 2rem);
  
  cursor: default;

  .Logo {
    position: absolute;
    z-index: 1;
    width : 100%;
    height : 100vh;
    left:0px;
    top:0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    opacity: 0.9;
    .Img {
      //margin: auto;
      margin: 0.5rem;
      max-width: 60px;
      min-width: 60px;
      width: 100%;
    }
  }

  .BG {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index : 0;
    /*
    opacity: 0.25;
      background:
      radial-gradient(50% 50% at 100% 0,#7d5c9e 0%  5% ,#e3dabf 6%  15%,#7d5c9e 16% 25%,#e3dabf 26% 35%,#7d5c9e 36% 45%,
       #e3dabf 46% 55%,#7d5c9e 56% 65%,#e3dabf 66% 75%,#7d5c9e 76% 85%,#e3dabf 86% 95%,
       #0000 96%),
      radial-gradient(50% 50% at 0 100%,#7d5c9e 0%  5% ,#e3dabf 6%  15%,#7d5c9e 16% 25%,#e3dabf 26% 35%,#7d5c9e 36% 45%,
       #e3dabf 46% 55%,#7d5c9e 56% 65%,#e3dabf 66% 75%,#7d5c9e 76% 85%,#e3dabf 86% 95%,
       #0000 96%),
      radial-gradient(50% 50%,#7d5c9e 0%  5% ,#e3dabf 6%  15%,#7d5c9e 16% 25%,#e3dabf 26% 35%,#7d5c9e 36% 45%,
       #e3dabf 46% 55%,#7d5c9e 56% 65%,#e3dabf 66% 75%,#7d5c9e 76% 85%,#e3dabf 86% 95%,
       #0000 96%),
      radial-gradient(50% 50%,#7d5c9e 0%  5% ,#e3dabf 6%  15%,#7d5c9e 16% 25%,#e3dabf 26% 35%,#7d5c9e 36% 45%,
       #e3dabf 46% 55%,#7d5c9e 56% 65%,#e3dabf 66% 75%,#7d5c9e 76% 85%,#e3dabf 86% 95%,
       #0000 96%) 24px 24px;
      background-size: 48px 48px;
      background-color: #7d5c9e;
      */
      background-color: rgba($l7-frame-color-2, 1);
      //background: rgb(2,0,36);
      background: linear-gradient(0deg, rgba($l7-white-color,1) 0%, rgba($l7-accent-color-3,1) 50%, rgba($l7-frame-color-2,1) 100%);
  }

  .Card {
    position: relative;
    flex-shrink: 0;
    z-index : 1;
    height: 1;
    width: 300px;
    height : calc( 300px * 9 / 16 );
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 2rem auto;
    border-radius: 0.75rem;
    border: 1px solid rgba($l7-accent-color-2, 1);
    //transition: all 0.25s ease-in;
    animation: tilt-shaking 3s linear infinite;
    .Amount {
      position: absolute;
      top : 0.25rem;
      right : 0.5rem;
      z-index: 2;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .sign {
        font-size: small;
        color : rgba($l7-white-color, 1);
        margin-right: 0.25rem;
      }
      .value {
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        font-size: 200%;
        color : rgba($l7-white-color, 1);
      }
    }
  }

  .anim-typewriter{
    position: relative; 
    z-index : 2;
    //width: 24em;
    margin: 0 auto;
    //border-right: 2px solid rgba(255,255,255,.75);
    font-size: 180%;
    text-align: center;
    //white-space: nowrap;
    //overflow: hidden; 
    h1, p, pre {
      animation: typewriter 2s steps(44) 1s 1 normal both;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      color : rgba($l7-accent-color-2, 1);
      word-wrap: break-word;
      white-space: pre-wrap;
      text-shadow: rgba($l7-dark-color,0.75) 1px 0 10px;
      &.From {
        padding-top: 4rem;
      }
    }
  }
  


}

@media screen and (max-width: 951px)  {
  .GiftCard {

  }
}

@media screen and (max-width: 751px)  {
  .GiftCard {

  }
}

@keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

  /* The typing effect */
@keyframes typewriter{
  from{opacity: 0;}
  to{opacity: 1;}
}
