@import "../../public/style.scss";

.Temp {
  display: flex;
  flex-direction: column;
  background-color: rgba( $l7-white-color, 1 );
  z-index : 1;
  height: 100vh;
  .Text {
    margin: auto;
    font-size: 200%;
    color : rgba($l7-frame-color , 1);
  }
}

@media screen and (max-width: 951px)  {
}