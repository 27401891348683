@import "../../../public/style.scss";


.To-Detail {
  display: flex;
  flex-direction: column;
  background-color: rgba( $l7-white-color, 1 );
  z-index : 1;
  top:0px;
  left:0px;
  width: 100%;
  height: calc(100vh - 0px);
  position: fixed;
  z-index : -2;
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: auto;
  &.Show {
    z-index : 99;
    opacity: 1;
  }
  &.In-Wrapper {
    position: relative;
    height: 100%;
  }

  .D-Header {
    display: flex;
    flex-direction: row;
    padding : 0rem 0rem;
    border-bottom: 2px solid rgba( $l7-dark-color, 0.05 );
    position: sticky;
    top: 0px;
    background-color: rgba( $l7-white-color, 1 );
    z-index :10;
    a {
      text-decoration: none;
    }
    .D-I-Holder {
      padding : 1rem 1rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transition: all 0.25s ease-in;
      cursor: pointer;
      text-decoration: none;
      .Fa, span{
        font-size: 125%;
        color : rgba( $l7-frame-color, 1 );
        padding : 0rem 0.25rem;
        transition: all 0.25s ease-in;
        text-decoration: none;
      }
      &:hover {
        background-color: rgba( $l7-frame-color, 1 );
        .Fa, span{
          color: rgba( $l7-white-color, 1 );
        }
      }
    }
  }

  .D-Wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    .D-Content {
      display: flex;
      flex-direction: row;
      height: auto;
      width : calc( 100% - 1rem);
      max-width: 1024px;
      padding : 1rem 0.5rem;
      padding-bottom: 1rem;
      margin: 0rem auto;
      
      .D-C-Cover {
        display: flex;
        flex-direction: column;
        //width: 533px;
        width: 300px;
        .D-C-C-Top {
          display: flex;
          flex-direction: row;
          padding: 0rem 0rem;
          margin-bottom: 0.5rem;
          cursor: default;
          width: 100%;
          justify-content: center;
          display: none;
          .D-C-C-Wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            //background-color: rgba( $l7-frame-color, 1 );
            margin: 0rem 0rem;
            padding : 0.25rem 0rem;
            .D-C-C-P-Original {
              font-size: 80%;
              text-decoration: line-through;
              color : rgba( $l7-frame-color, 0.5 );
              text-align: left;
              align-self: flex-start;
            }
            .D-C-C-P-Price {
              font-size: 200%;
              color : rgba( $l7-dark-color, 1 );
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              .Appendix {
                font-size: 60%;
                opacity: 0.8;
                font-weight:initial;
                padding-right: 0.5rem;
              }
            }
          }
          
        }
        .D-C-C-Img {
          height : 300px;
          width: fit-content;
          background-color: rgba( $l7-accent-color-2, 1 );
          //width: 533px;
          width : 300px;
        }
        .D-C-C-Gallery {
          overflow: auto;
          width : calc( 100% - 0px);
          display: flex;
          flex-direction: row;
          margin-top: 0.5rem;
          background-color: rgba( $l7-accent-color-2, 0.25 );
          img {
            cursor: pointer;
            width :100px;
            margin :0rem 0.25rem;
            transition: all 0.25s ease-in;
            &:first-child {
              margin-left: 0rem;
            }
            &:last-child {
              margin-right: 0rem;
            }
            &:hover {
              transform: scale(0.9);
            }
          }
        }
      }
      
      .D-C-Content {
        display: flex;
        flex-direction: column;
        padding : 0rem 0rem;
        padding-left: 2.5rem;
        flex-grow: 1;
        height: fit-content;
        .D-C-Title {
          font-size: 250%;
          justify-content: flex-start;
          display: flex;
          flex-direction: row;
          align-items: center;
          .D-Badge {
            font-size: 50%;;
            background-color: rgba( $l7-frame-color-2 , 1 ) ;
            padding : 0.25rem 0.5rem;
            margin-right: 0.25rem;
            color : rgba( $l7-white-color, 1 );
            border-radius: 0.75rem;
          }
        }
        .D-C-Valid {
          font-size: 100%;
          color :rgba( $l7-dark-color, 0.25 );
        }
        .D-C-Terms {
          display: flex;
          flex-direction: column;
          padding-top: 2rem;

          .D-C-Tabs {
            display: flex;
            flex-direction: row;
            margin-bottom: -2px;
            position: relative;
            z-index : 5;
            
            .D-C-Tab {
              padding: 0.5rem 1rem;
              cursor: pointer;
              transition: all 0.25s ease-in;
              color : rgba( $l7-dark-color, 0.25 );
              border: 2px solid rgba( $l7-dark-color, 0 );
              border-bottom-width: 0px;
              min-width: 80px;
              text-align: center;
              &.Selected {
                font-weight: bolder;
                color : rgba( $l7-frame-color, 1 );
                border: 2px solid rgba( $l7-accent-color-2, 1 );
                border-bottom-width: 0px;
                background-color: rgba( $l7-white-color, 1 );
              }
              
            }
            
          }
          
          .D-C-T-Content {
            position: relative;
            z-index : 1;
            display: flex;
            flex-direction: column;
            transition: all 0.25s ease-in;
            border: 2px solid rgba( $l7-accent-color-2, 1 );
            width : calc( 100% - 4rem - 4px);
            padding : 2rem;
            font-size: 100%;
            color : rgba( $l7-frame-color, 1 );
            line-height: 18px;
            white-space: pre-line;
            text-wrap: wrap;
          }
        }
        
      }
      
    }
    
    .D-Options {
      display: flex;
      flex-direction: column;
      padding : 0rem 0.5rem;
      width : calc( 100% - 1rem);
      max-width: 1024px;
      margin: 0rem auto;
      padding-bottom: 15rem;
      .Options {
        width : calc( 100% - 2px - 4rem);
        padding : 2rem;
        margin: 0rem auto;
        margin-bottom: 2rem;
        min-height: 100px;
        background-color: rgba( $l7-accent-color-2, 0.25 );
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .O-Title {
          width: 100%;
          font-size: 125%;
          font-weight: bold;
          margin-bottom: 0.5rem;
          color : rgba( $l7-accent-color, 1 );
        }
        .D-Option-Wrapper {
          display: flex;
          flex-direction: column;
          padding: 1rem 1rem;
          border: 1px solid rgba( $l7-accent-color, 0.1 );
          width: 300px;
          flex-shrink: 0;
          transition: all 0.25s ease-in;
          &.Error {
            border: 2px solid rgba( 175, 20, 20, 0.5 );
            background-color: rgba( 175, 20, 20, 0.1 );
          }
          .D-Option-Title {
            padding: 0rem;
            margin-bottom: 1rem;
            font-size: 100%;
            //font-weight: bold;
            color : rgba( $l7-frame-color, 1 );
          }
          .D-Option-Group {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            .D-Option-Group-Option {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 0rem 1rem;
              .D-Option-Group-Option-Checkbox[type="submit"] {
                margin: auto 0rem;
                background: rgba( $l7-accent-color, 0.1 );
                border: 1px solid rgba( $l7-accent-color, 1 );
                //outline: 1px solid rgba( $l7-accent-color, 1 ); ;
                &:checked {
                  background: rgba( $l7-accent-color, 1 );
                }
              }
              .D-Option-Group-Option-Name {
                margin: auto 1rem;
                color: rgba( $l7-accent-color, 1 );
                font-weight: bold;
              }
              .D-Option-Group-Option-Price {
                margin: auto 0rem;
                color: rgba( $l7-accent-color, 1 );
                font-weight: bold;
              }
            }
          }
        }
      }
      .D-Pick-Up {
        display: flex;
        flex-direction: row;
        width : calc( 100% - 0rem - 0px);
        margin: 1rem auto;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        z-index : 4;
        transition: all 0.25s ease-in;
        &.Selected {
          z-index: 6;
        }
        .Column {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .Title {
            font-size: 100%;
            color : rgba( $l7-frame-color, 1 );
            margin-bottom: 0.25rem;
          }
          .react-datepicker-wrapper {
            .react-datepicker-ignore-onclickoutside {
              &:placeholder {
                color : rgba( $l7-frame-color, 1 );
              }
              &:-ms-input-placeholde {
                color : rgba( $l7-frame-color, 1 );
              }
            }
          }
          input {
            &::placeholder {
              color : rgba( $l7-frame-color, 1 );
            }
            &::-ms-input-placeholde {
              color : rgba( $l7-frame-color, 1 );
            }
          }
        }
        .Row{
          display: flex;
          flex-direction: row;
          .Select, input {
            text-align: center;
            padding : 0.25rem 0.5rem;
            background-color: transparent;
            border-style: none;
            border: 2px solid rgba( $l7-frame-color, 1 );
            color : rgba( $l7-frame-color, 1 );
            margin: 0.25rem 0rem;
            min-width : 100px;
            margin-left: 0.5rem;
            //height : 30px;
            //padding : 0.25rem 0.5rem;
            cursor: pointer;
          }
        }
      }
      .D-Contact {
        padding-top: 1rem;
        border-top: 2px solid rgba( $l7-dark-color, 0.05 );
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        p {
          color : rgba( $l7-dark-color, 0.5 );
          font-weight: bold;
          margin-top: 0rem;
          margin-bottom: 0.25rem;
        }
        a {
          color : rgba( $l7-frame-color, 1 );
          font-weight: bold;
          text-decoration: underline;
          font-size: 125%;
        }
      }
      
    }
      
    .D-Selections {
      display: flex;
      flex-direction: column;
      padding : 0rem 0.5rem;
      width : calc( 100% - 1rem);
      max-width: 1024px;
      margin: 0rem auto;
      padding-bottom: 15rem;
      
      .D-Selection {
        display: flex;
        flex-direction: column;
        background-color: rgba( $l7-accent-color-2, 0.25 );
        padding : 1rem 1rem;
        margin: 0.5rem 0rem;
        &:first-child {
          margin-top: 0rem;
        }
        &:last-child {
          margin-bottom: 0rem;
        }
        .D-S-Title {
          font-size: 125%;
          color : rgba( $l7-dark-color, 1 );
          &.Error {
            color : rgba( red, 1 );
            font-weight: bold;
          }
        }
        .D-S-SubTitle {
          font-size: 100%;
          color : rgba( $l7-dark-color, 0.75 );
          margin-bottom: 2rem;
        }
        .D-S-Wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin : 1rem 0rem;
          width: calc( 100% - 0rem);
          .D-S-W-Holder {
            display: flex;
            flex-direction: row;
            width: calc( 100% - 0rem);
            align-items: center;
            .D-S-W-H-Checkbox {
              margin: auto 0rem;
              margin-right: 1rem;
              background: rgba( $l7-accent-color, 0.1 );
              border: 1px solid rgba( $l7-accent-color, 1 );
              //outline: 1px solid rgba( $l7-accent-color, 1 ); ;
              border-radius: 50%;
              //outline: 3px solid rgba( $l7-frame-color, 1 );
              &:checked {
                background: rgba( $l7-accent-color, 1 );
              }
            }
            .D-S-W-H-Img {
              width : 60px;
              height: 60px;
              background-color: rgba( $l7-dark-color, 1 );
              border-radius: 0.5rem;
            }
            .D-S-W-H-Name {
              font-family: inherit;
              padding : 0rem 1rem;
              flex-grow: 1;
              white-space: wrap;
            }
            .D-S-W-H-Amount {
              flex-shrink: 0;
              margin-left: auto;
            }
          }
          .D-S-W-Options {
            display: flex;
            flex-direction: column;
            padding : 1rem;
            border-bottom: 1px solid rgba( $l7-frame-color, 0.25 );
            padding-left:  calc( 60px + 2rem + 0rem);
            .D-S-W-O-Title {
              font-size: 100%;
              color : rgba( $l7-dark-color, 1 );
              &.Error {
                background-color: rgba( red, 1 );
                color : rgba( $l7-white-color, 1 );
                font-weight: bold;
                padding : 0rem 0.5rem
              }
            }
            .D-S-W-O-SubTitle {
              font-size: 80%;
              color : rgba( $l7-dark-color, 0.8 );
              &.Error {
                background-color: rgba( red, 1 );
                color : rgba( $l7-white-color, 1 );
                font-weight: bold;
                padding : 0rem 0.5rem
              }
            }
            .D-S-W-O-Option {
              display: flex;
              flex-direction: row;
              padding : 0.25rem 1rem;
              align-items: center;
              padding-right: 0rem;
              .D-S-W-O-Checkbox {
                margin: auto 0rem;
                margin-right: 1rem;
                background: rgba( $l7-accent-color, 0.1 );
                border: 1px solid rgba( $l7-accent-color, 1 );
                //outline: 1px solid rgba( $l7-accent-color, 1 ); ;
                border-radius: 50%;
                //outline: 3px solid rgba( $l7-frame-color, 1 );
                &:checked {
                  background: rgba( $l7-accent-color, 1 );
                }
              }
              .D-S-W-O-Name {
                padding : 0rem;
                margin: auto 0rem;
                font-family: inherit;
                flex-grow: 1;
              }
              .D-S-W-O-Price {
                margin-left: auto;
                padding : 0rem;
                margin: auto 0rem;
                font-family: inherit;
                flex-shrink: 0;
              }
            }
          }
        }
      }
    }
  }

  .D-Cart {
    padding : 1rem 0.5rem;
    width : calc( 100% - 1rem);
    max-width: 1024px;
    margin: 0rem auto;
    
    position: sticky;
    bottom : 0px;
    display: flex;
    flex-direction: row;

    flex-shrink: 0;
    margin-top: auto;

    height: calc( 60px + 0rem);
    border-top: 1px solid rgba( $l7-accent-color-2, 0.25 );
    background-color: rgba( $l7-white-color, 0.8 );
    backdrop-filter: blur(2px);
    z-index : 5;
    //padding-bottom: 100px;
    .D-C-Q-Wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0rem 0.25rem;
      margin-left: auto;
      position: relative;
      height: 100%;
      .Q-Text {
        position: absolute;
        z-index : -1;
        left:0px;
        top:2px;
        width:100%;
        text-align: center;
        font-size: 80%;
        color : rgba( $l7-frame-color, 0.5 );
      }
      .Q-Wrapper {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        .Fa-Wrapper {
          height: calc( 100% - 0rem - 4px);
          transition: all 0.25s ease-in;
          cursor: pointer;
          color : rgba( $l7-frame-color, 1 );
          border: 2px solid rgba( $l7-frame-color, 1 );
          margin: 0rem 0.25rem;
          padding : 0rem 0.25rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .Fa {  
            font-size: 100%;
            padding:0rem 0.25rem;
          }
          &:hover {
            color : rgba( $l7-white-color, 1 );
            background-color: rgba( $l7-frame-color, 1 );
          }
        }
        .Quantity {
          background: transparent;
          border: 2px solid rgba( $l7-frame-color, 1 );
          height: calc( 100% - 4px);
          width : 30px;
          text-align: center;
          font-size: 150%;
          color : rgba( $l7-frame-color, 1 );
          transition: all 0.25s ease-in;
          padding:0rem;
          &:hover {
            background: rgba( $l7-frame-color, 0.1 );
          }
        }
      }
    }
    .D-C-Confirm {
      display: flex;
      position: relative;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: calc( 100% - 4px );
      padding : 0rem 1rem;
      border: 2px solid rgba( $l7-frame-color, 1 );
      background-color: rgba( $l7-white-color, 1 );
      color : rgba( $l7-frame-color, 1 );
      transition: all 0.25s ease-in;
      cursor: pointer;
      .Text {
        transition: all 0.25s ease-in;
        font-size: 150%;
        color : rgba( $l7-frame-color, 1 );
      }
      .Sub {
        transition: all 0.25s ease-in;
        font-size: 125%;
        margin-left: 1rem;
        color : rgba( $l7-frame-color, 1 );
      }
      &:hover:not(.Disabled) {
        //z-index : 99;
        //transform: scale( 4 );
        background-color: rgba( $l7-frame-color, 1 );
        .Text {
          color : rgba( $l7-white-color, 1 );
        }
        .Sub {
          color : rgba( $l7-white-color, 0.75 );
        }
      }
      &.Clicked {
        z-index : 99;
        transform: scale( 2 );
        opacity: 0.25;
        filter: blur(5px);
        &:hover {
          background-color: rgba( $l7-white-color, 1 );
          border-color: transparent;
          .Text, .Sub{
            color: rgba( $l7-frame-color, 1 );
          }
        }
      }
      &.Disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }

  .D-S-Gallery {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index : -1;
    opacity: 0;
    top : 0px;
    left: 0px;
    right : 0px;
    bottom : 0px;
    background-color: rgba( $l7-dark-color, 0.75 );
    backdrop-filter: blur(4px);
    &.Show {
      z-index : 11;
      opacity: 1;
    }
    .D-Photo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      position: relative;
      height: calc( 100% - 61px - 4rem );
      .D-Img {
        margin: auto;
        height: calc( 100% - 4rem );
        width: fit-content;
        transition: all 0.25s ease-in;
        position: absolute;
        z-index :-1;
        opacity: 0;
        &.Selected {
          z-index :1;
          opacity: 1;
        }
      }
      .Left, .Right {
        position : absolute;
        z-index : 9;
        top:0px;
        bottom:0px;
        width : 33%;
        min-width: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .Fa {
          color : rgba( $l7-white-color, 0.25 );
          transition : all 0.25s ease-in;
          font-size: 150%;
        }
        &:hover {
          .Fa {
            color : rgba( $l7-frame-color, 1 );
          }
        }
      }
      .Left {
        left : 0px;
        padding-left: 1rem;
      }
      .Right {
        right : 0px;
        align-items: flex-end;
        padding-right: 1rem;
      }
      .Dots-Selector {
        position: relative;
        z-index: 10;
        margin-bottom: auto;
        background-color: rgba( $l7-dark-color, 0.8 );
        backdrop-filter: blur(4px);
        transition: all 0.25s ease-in;
        display: flex;
        flex-direction: row;
        margin: auto;
        margin-bottom: 1rem;
        opacity: 0.5;
        padding : 0.5rem 1.5rem;
        .Dot {
          transition: all 0.25s ease-in;
          height: 10px;
          width: 10px;
          border: 2px solid rgba( $l7-frame-color, 1 );
          background-color: transparent;
          border-radius: 50%;
          margin : 0rem 0.25rem;
          &.Selected {
            background-color: rgba( $l7-frame-color, 1 );
          }
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }

}


@media screen and (max-width: 951px)  {
  .To-Detail {
    .D-Wrapper {
      .D-Content {
        flex-direction: column; 
        .D-C-Cover {
          width: calc( 100% - 0rem);
          .D-C-C-Img {
            width: 100%;
            height: fit-content;
          }
        }
        .D-C-Content {
          padding-left: 0rem;
        }
      }
      .D-Options {
        .Options {
          .D-Option-Wrapper {
            width: calc(100% - 2rem);
            .D-Option-Group {
              .D-Option-Group-Option {

              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 751px)  {
  .To-Detail {
    .D-Wrapper {
      
    }
    .D-Cart {
      padding-bottom: 100px;
    }
  }
}
