@import "../../public/style.scss";

.Login {
  position : fixed;
  top:0px;
  left: 0px;
  bottom : 0px;
  right : 0px;
  display: flex;
  flex-direction: column;
  z-index : 100;
  height: 100vh;
  transition: all 0.25s ease-in;
  .Content {
    transition: all 0.25s ease-in;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    width : 320px;
    //height: 500px;
    height: fit-content;
    padding : 1.5rem 1rem;
    background-color: rgba( $l7-frame-color-2-sub, 1 );
    border: 1px solid rgba( $l7-frame-color-2, 0.5 );
    border-radius: 1rem;
    //box-shadow: 0px 0px 5px rgba( $l7-white-color, 0.5 );
    .Close-Btn {
      position: absolute;
      top : 0.5rem;
      right: 0.5rem;
      color : rgba( $l7-frame-color-sub, 1 );
      font-size: 150%;
      transition: all 0.25s ease-in;
      &:hover {
        color : rgba( $l7-frame-color-2, 1 );
      }
    }
    .Tel {
      display: flex;
      flex-direction: row;
      .Region {
        background: rgba( $l7-frame-color, 0.5 );
        margin: auto 0rem;
        padding : 0.5rem;
        border-radius: 0.25rem;
        border-style: none;
      }
    }
    .Line {
      
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      transition: all 0.25s ease-in;
      &:hover {
        .Input {
          background-color: rgba( $l7-dark-color, 0.1 );
          color : rgba( $l7-dark-color, 1 );
        }
      }
      .Title {
        font-size: 80%;
        color: rgba( $l7-frame-color-sub, 1 );
        margin-bottom: 0.25rem;
        &.Warn {
          color: rgba( red, 1 );
        }
      }
      .Input {
        transition: all 0.25s ease-in;
        background-color: transparent;
        border-style: none;
        border-bottom: 2px solid rgba( $l7-frame-color-sub, 1 );
        padding: 0.25rem 1rem;
        color : rgba( $l7-frame-color-sub, 1 );
        height : 30px;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
      }
      
    }

    .Link {
      margin-top : 0.5rem;
      //text-decoration: underline;
      color : rgba( $l7-frame-color-sub, 0.5 );
      font-style: italic;
      font-size: 90%;
      text-align: center;
      cursor: pointer;
      transition: all 0.25s ease-in;
      &:hover {
        color : rgba( $l7-dark-color, 0.5 );
      }
    }

    .Btn {
      background-color: rgba( $l7-frame-color-sub, 1 );
      color : rgba( $l7-white-color, 1 );
      padding : 0.75rem 1rem;
      text-align: center;
      //width :100%;
      transition: all 0.25s ease-in;
      border-radius: 0.5rem;
      margin-top: 1rem;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        background-color: rgba( $l7-frame-color-2, 1 );
      }
      &.L {
        margin: 0rem 0.5rem;
        margin-top: 1rem;
      }
    }

  }
  .Frame {
    background-color: rgba( $l7-dark-color, 0.8 );
    backdrop-filter : blur(5px);
    position: absolute;
    z-index : -1;
    top : 0px;
    bottom : 0px;
    left: 0px;
    right: 0px;
  }
  &.Show {
    z-index : 100;
    opacity: 1;
  }
  &.Hide {
    z-index : -9;
    opacity: 0;   
  }
}

@media screen and (max-width: 951px)  {
  .Login {

  }
}

@media screen and (max-width: 751px)  {
  .Login {

  }
}