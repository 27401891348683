@import "../../../public/style.scss";


.ToCart {
  display: flex;
  flex-direction: column;
  background-color: rgba( $l7-white-color, 1 );
  z-index : -1;
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: auto;
  position: fixed;
  top: 0px;
  left:0px;
  right : 0px;
  bottom: 0px;
  &.Show {
    z-index : 19;
    opacity: 1;
  }
  .C-Header {
    display: flex;
    flex-direction: row;
    padding : 0rem 0rem;
    border-bottom: 2px solid rgba( $l7-dark-color, 0.05 );
    position: sticky;
    top: 0px;
    background-color: rgba( $l7-white-color, 1 );
    z-index :10;
    .C-I-Holder {
      padding : 1rem 1rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transition: all 0.25s ease-in;
      cursor: pointer;
      .Fa, span{
        font-size: 125%;
        color : rgba( $l7-frame-color, 1 );
        padding : 0rem 0.25rem;
        transition: all 0.25s ease-in;
      }
      &:hover {
        background-color: rgba( $l7-frame-color, 1 );
        .Fa, span{
          color: rgba( $l7-white-color, 1 );
        }
      }
    }
  }
  .C-Content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 751px;
    width: 100%;
    margin: 0rem auto;
    .C-Title {
      width : 100%;
      text-align: center;
      //display: flex;
      //flex-direction: row;
      padding : 1rem 0rem;
      font-size: 125%;
      font-weight: bold;
      color : rgba( $l7-frame-color, 1 );
      border-bottom: 2px solid rgba( $l7-frame-color, 1 );
    }
    .C-Holder {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: auto;
      .C-Shop {
        display: flex;
        flex-direction: column;
        padding : 1rem 1rem;
        border: 1px solid rgba( $l7-accent-color-2 , 1 );
        border-radius: 0.5rem;
        margin: 1rem;
        .C-S-Title {
          background-color: rgba( $l7-accent-color-2 , 1 );
          text-align: center;
          margin-bottom: 0.25rem;
          padding : 0.5rem 0rem;
        }
        .Line {
          display: flex;
          flex-direction: row;
          //height: 60px;
          --lheight : 60px;
          padding : 0.25rem 1rem;
          border-bottom: 1px solid rgba( $l7-dark-color, 0.05 );
          cursor: pointer;
          a {
            text-decoration: none;
          }
          &.Error {
            justify-content: center;
            align-items: center;
            color : rgba( $l7-frame-color, 1 );
            font-weight: bold;
            font-size: larger;
          }
          .L-C {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            a {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
            .L-Img {
              height : var(--lheight);
              width: calc( var(--lheight) * 16 / 9 );
              background-color: rgba( $l7-frame-color, 1 );
            }
            .L-Col {
              display: flex;
              flex-direction: column;
              padding:0rem 1rem;
              flex-grow: 1;
              .L-Title {
                font-size: 125%;
                color: rgba( $l7-frame-color, 1 );
                justify-content: flex-start;
                display: flex;
                flex-direction: row;
                align-items: center;
                .L-Badge {
                  font-size: 50%;;
                  background-color: rgba( $l7-frame-color-2 , 1 ) ;
                  padding : 0.25rem 0.5rem;
                  margin-right: 0.25rem;
                  color : rgba( $l7-white-color, 1 );
                  border-radius: 0.75rem;
                }
              }
            }
          }
          .L-Q-Col {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: center;
            align-items: flex-end;
            .L-Q-Price {
              font-size: 125%;
              margin: 0rem;
              margin-right: 0rem;
              padding: 0rem;
              color : rgba( $l7-frame-color, 1 );
              font-family: inherit;
              //font-weight: bolder;
              //margin-bottom: 0.25rem;
            }
            .L-Q-Wrapper {
              display: flex;
              flex-direction: row;
              flex-shrink: 0;
              //height: 60px;
              height: auto;
              //align-self: center;
              align-items: center;
              justify-content: center;
              .Fa-Wrapper {
                height: calc( 100% - 0rem - 2px);
                max-height: 20px;
                transition: all 0.25s ease-in;
                cursor: pointer;
                color : rgba( $l7-frame-color, 1 );
                border:1px solid rgba( $l7-frame-color, 1 );
                margin: 0rem;
                padding : 0rem 0rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .Fa {  
                  font-size: 80%;
                  padding:0rem 0.25rem;
                }
                &:hover {
                  color : rgba( $l7-white-color, 1 );
                  background-color: rgba( $l7-frame-color, 1 );
                }
                &:first-child {
                  margin-right: 0.1rem;
                }
                &:last-child {
                  margin-left: 0.1rem;
                }
              }
              .Quantity {
                background: transparent;
                border: 1px solid rgba( $l7-frame-color, 1 );
                height: calc( 100% - 2px);
                max-height: 20px;
                width : 30px;
                text-align: center;
                font-size: 100%;
                color : rgba( $l7-frame-color, 1 );
                transition: all 0.25s ease-in;
                padding:0rem;
                &:hover {
                  background: rgba( $l7-frame-color, 0.1 );
                }
              }
            }
          }
          .L-Remove {
            display: flex;
            flex-direction: column;
            margin-left: 0.5rem;
            justify-content: center;
            align-items: center;
            transition: all 0.25s ease-in;
            padding : 0rem 0.5rem;
            .L-Remove-Fa {
              color : rgba( rgb(175, 19, 19), 1 );
              opacity: 0.5;
              font-size: 125%;
              transition: all 0.25s ease-in; 
            }
            &:hover {
              background-color: rgba( rgb(175, 19, 19), 1 );
              .L-Remove-Fa {
                color : rgba( $l7-white-color, 1 );
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .L-Buy {
      display: flex;
      flex-direction: column;
      justify-content: center;
      //align-self: center;
      padding : 1rem;
      background-color: rgba( $l7-white-color, 0.8 );
      backdrop-filter: blur(4px);
      position: sticky;
      bottom:0px;
      z-index : 10;
      border-top: 2px solid rgba( $l7-dark-color, 0.05 );
      .btn {
        background-color: rgba( $l7-frame-color, 1 );
        padding : 0.5rem 0rem;
        width: 100%;
        text-align: center;
        color : rgba( $l7-white-color, 1 );
        transition: all 0.25s ease-in;
        font-size: 120%;
        cursor: pointer;
        &:hover {
          background-color: rgba( $l7-accent-color, 1 );
        }
        &.Disabled {
          opacity: 0.5;
          &:hover {
            background-color: rgba( $l7-frame-color, 1 );
          }
        }
      }
    }
  }

  .To-Popup {
    position: fixed;
    top : 0rem;
    bottom : 0rem;
    left: 0rem;
    right : 0rem;
    z-index : -1;
    background-color: rgba( $l7-dark-color, 0.5 );
    backdrop-filter: blur(4px);
    opacity: 0;
    transition: all 0.25s ease-in;
    &.Show {
      z-index : 99;
      opacity: 1;
    }
    .Frame {
      position: absolute;
      left: 0px;
      right : 0px;
      top:0px;
      bottom:0px;
      z-index : 1;
    }
    .Holder {
      position: absolute;
      left: 2rem;
      right : 2rem;
      top: 2rem;
      bottom: 2rem;
      z-index : 2;
      background-color: rgba( $l7-white-color, 1 );
    }
  }

}


@media screen and (max-width: 951px)  {
  .ToCart {

  }
}

@media screen and (max-width: 751px)  {
  .ToCart {
    .C-Content { 
      .C-Title {
      }
      .C-Holder {
        .Line {
          height: auto;
          .L-C {
            margin-top: 0.5rem;
            flex-direction: column;
            align-items: flex-start;
            .L-Img {
              --lheight : 40px;
            }
            .L-Col {
              padding: 0px;
              a {
                max-width: 250px;
              }
              .L-Pickup {
                .Select, input {
                  font-size: 80%;
                }
                .Select {
                  min-width: 125px;
                  max-width: 125px;
                }
              }
            }
          }
          .L-Q-Col {
            .L-Q-Wrapper {
              //height: 40px;
              height: auto;
              flex-direction: column-reverse;
              .Fa-Wrapper {
                height:20px;
                font-size: 80%;
              }
              .Quantity {
                height: 20px;
                border-top-width: 0px;
                border-bottom-width: 0px;
                font-size: 80%;
              }
            }
          }
          
        }
      }
    } 
  }
}
