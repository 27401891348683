@import "../../public/style.scss";

.Webview {
  position : fixed;
  height : 100vh;
  width : 100%;
  top : 0px;
  left : 0px;
  z-index : -9;
  opacity: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  transition: all 0.25s ease-in-out;
  &.Show {
    z-index : 99;
    opacity: 1;
  }
  .Control {
    flex-direction: row;
    display: flex;
    flex-shrink: 0;
    border-bottom: 1px solid rgba( $l7-dark-color, 0.05 );
    padding : 0.5rem 1rem;
    .Close-Btn {
      flex-direction: row;
      display: flex;
      align-items: center;
      transition: all 0.25s ease-in-out;
      cursor: pointer;
      .Icon {
        transition: all 0.25s ease-in-out;
        color : rgba( $l7-frame-color, 1 );
        font-size: 25px;
      }
      .Text {
        transition: all 0.25s ease-in-out;
        color : rgba( $l7-frame-color, 1 );
        font-size: 20px;
        margin-left: 0.5rem;
      }
      &:hover {
        .Icon,.Text {
          color : rgba( $l7-frame-color-2, 1 );
        }
      }
    }
  }
  .Iframe {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    border-width: 0px;
  }
}

@media screen and (max-width: 951px)  {
  .Webview {

  }
}

@media screen and (max-width: 751px)  {
  .Webview {

  }
}